import APIkit from ".";

class ZoneService {
  getAll(locationId) {
    return APIkit.get(`/api/v1/locations/${locationId}/zones`);
  }

  get({ locationId, zoneId }) {
    return APIkit.get(`/api/v1/locations/${locationId}/zones/${zoneId}`);
  }

  create(data) {
    return APIkit.post("/api/v1/zones", data);
  }

  update(id, data) {
    return APIkit.patch(`/api/v1/zones/${id}`, data);
  }

  delete(id) {
    return APIkit.delete(`/api/v1/zones/${id}`);
  }

  findById(title) {
    return APIkit.get(`/api/v1/zones?id=${title}`);
  }
}

export default ZoneService;
