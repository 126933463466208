import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  capitalize,
  makeStyles,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import React, { useEffect, useState } from "react";
import SessionService from "../../services/SessionService";
import ZoneService from "../../services/ZoneService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 10px",
  },
  addressDiv: {
    marginRight: theme.spacing(1),
  },
  lotDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  plateNumberInput: {
    width: "160px",
  },
  plateStateInput: {
    width: "120px",
  },
  hoursInput: {
    width: "90px",
    height: "51px",
    marginLeft: "6px !important",
  },
  licenseInputDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

const AddLotSession = () => {
  const classes = useStyles();
  const { lotId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [vehiclePlate, setVehiclePlate] = useState("");
  const [vehicleState, setVehicleState] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date("2014-08-18"));
  const [totalCost, setTotalCost] = useState(0);
  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState("");

  const handleSelect = (val) => {
    setZone(val.target.value);
  };

  const handleChange = (e) => {
    if (errorMessage) {
      setErrorMessage("");
    }

    const { name, value } = e.target;
    if (name === "vehiclePlate") {
      setVehiclePlate(value.toUpperCase());
    } else {
      setVehicleState(value.toUpperCase());
    }
  };

  const addSession = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (errorMessage) setErrorMessage("");

    if (!vehiclePlate || !vehicleState) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    if (!zone) {
      setErrorMessage("Please select a zone.");
      return;
    }

    if (!startDate) {
      setErrorMessage("Please select a start date.");
      return;
    }

    if (!totalCost) {
      setErrorMessage("Please enter a total cost.");
      return;
    }

    if (totalCost < 0) {
      setErrorMessage("Total cost cannot be negative.");
      return;
    }

    if (isNaN(totalCost)) {
      setErrorMessage("Please enter a valid total cost.");
      return;
    }

    try {
      const sessionService = new SessionService();
      console.log({ zone });
      console.log({ lotId });
      const { data } = await sessionService.addSession({
        plateNumber: vehiclePlate,
        state: vehicleState,
        totalCost,
        rate: totalCost,
        zoneId: zone,
        locationId: lotId,
        zoneNumber: zone.zoneNumber,
        startTime: startDate,
      });

      if (data.error) {
        setErrorMessage(data.error);
        return;
      }

      setSuccessMessage("Successfully added session!");
      setVehiclePlate("");
      setVehicleState("");
      setTotalCost(0);
      setZone("");
      setStartDate("");
    } catch (err) {
      console.log(err);
      setErrorMessage("Something went wrong!, Please try again or contact us.");
    } finally {
      setIsLoading(false);
    }
  };

  const getLocationZones = async () => {
    setIsLoading(true);

    try {
      const zoneService = new ZoneService();
      const { data } = await zoneService.getAll(lotId);

      if (data.error) {
        setErrorMessage(data.error);
        return;
      }

      setZones(data.data.zones);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLocationZones();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            textTransform: "capitalize",
            fontSize: "26px",
            fontWeight: "900",
            marginBottom: "50px",
          }}
        >
          Add Session
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className={classes.licenseInputDiv}>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            onChangeCapture={(e) => setStartDate(e.target.value)}
            defaultValue={startDate}
            classes={{ root: classes.plateNumberInput }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div style={{ width: "20px" }}></div>
          <TextField
            label={"Total Cost"}
            name="totalCost"
            classes={{ root: classes.plateStateInput }}
            InputLabelProps={{
              style: { fontSize: "14px" },
            }}
            InputProps={{
              style: { textTransform: "uppercase" },
              maxLength: 10,
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon style={{ color: "light-gray" }} />{" "}
                </InputAdornment>
              ),
            }}
            variant="outlined"
            onChange={(e) => setTotalCost(e.target.value)}
            value={totalCost}
            disabled={isLoading}
          />{" "}
        </div>
        <div className={classes.licenseInputDiv}>
          <TextField
            label={"License Plate Number"}
            name="vehiclePlate"
            classes={{ root: classes.plateNumberInput }}
            InputLabelProps={{
              style: { fontSize: "14px" },
            }}
            inputProps={{
              maxLength: 8,
              style: { textTransform: "uppercase" },
            }}
            variant="outlined"
            onChange={handleChange}
            value={vehiclePlate}
            disabled={isLoading}
          />{" "}
          <div style={{ width: "20px" }}></div>
          <TextField
            label={"License State"}
            name="vehicleState"
            classes={{ root: classes.plateStateInput }}
            InputLabelProps={{
              style: { fontSize: "14px" },
            }}
            inputProps={{
              maxLength: 2,
              style: { textTransform: "uppercase" },
            }}
            variant="outlined"
            onChange={handleChange}
            value={vehicleState}
            disabled={isLoading}
          />{" "}
        </div>
        <div style={{ width: 300, marginBottom: 20, marginTop: 5 }}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              style={{ paddingLeft: 8 }}
            >
              Zones
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              className={classes.textField}
              defaultValue={zones.length > 0 ? zones[0]?.id : ""}
              value={zone}
              onChange={handleSelect}
              label="Zone"
            >
              {zones &&
                zones.length &&
                zones.map((loc, idx) => {
                  return (
                    <MenuItem value={loc._id} key={idx}>
                      {capitalize(loc.zoneNumber)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>

        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={(e) => addSession(e)}
          style={{ color: "#fff", width: "300px" }}
          className={classes.button}
        >
          Add Session
        </Button>

        {errorMessage && (
          <div style={{ marginTop: "20px", color: "red" }}>{errorMessage}</div>
        )}

        {successMessage && (
          <div style={{ marginTop: "20px", color: "green" }}>
            {successMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddLotSession;
