import React from "react";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import { makeStyles } from "@material-ui/core";
import { formatPrice } from "../utils/FrontEndHelpers";
import CirclueLoader from "./shared/CircleLoader";
import { cl } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  locationsNumber: {
    padding: 30,
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: 10,
    marginBottom: 20,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "unset",
      flexDirection: "column",
    },
  },
  statLine: {
    minWidth: 80,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "unset",
    },
  },
}));

const InfoHeader = ({ isLoading, currentDayStats }) => {
  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div>
          <CirclueLoader />
        </div>
      ) : (
        <>
          <div className={classes.locationsNumber}>
            <div className={classes.statLine}>
              {/* // logo sec */}
              <div
                style={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  backgroundColor: cl.brown,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <DirectionsCarIcon
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    marginRight: 15,
                    color: cl.white,
                  }}
                  fontSize="small"
                />{" "}
              </div>

              {/* // info sec */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>Total Cars</div>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: 800 }}>
                    {currentDayStats.totalCars}
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className={classes.statLine}>
              {/* // logo sec */}
              <div
                style={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  backgroundColor: cl.primary,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LocalParkingIcon
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    marginRight: 15,
                    color: cl.white,
                  }}
                  fontSize="small"
                />
              </div>

              {/* // info sec */}
              <div
                className={classes.statLine}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div>Parked</div>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: 800 }}>
                    {Number(currentDayStats.totalParked)}
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className={classes.statLine}>
              {/* // logo sec */}
              <div
                style={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  backgroundColor: cl.neutral,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ConfirmationNumberIcon
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    marginRight: 15,
                    color: cl.white,
                  }}
                  fontSize="small"
                />
              </div>

              {/* // info sec */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>Requested</div>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: 800 }}>
                    {Number(currentDayStats.totalRequested)}
                  </div>
                </div>
              </div>
            </div>

            {/* { TODO: check if there's a use and their role} */}
            {/* <hr /> */}

            {/* <div className={classes.statLine}>
              <div
                style={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  backgroundColor: cl.primary,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AttachMoneyIcon
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    marginRight: 15,
                    color: cl.white,
                  }}
                  fontSize="small"
                />
              </div>

              <div
                className={classes.statLine}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div>Today's Revenue</div>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: 800 }}>
                    {formatPrice(Number(currentDayStats.totalRevenue) / 100)}
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          {/* <hr /> */}
        </>
      )}
    </div>
  );
};

export default InfoHeader;
