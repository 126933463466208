import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import axios from "axios";
import CircleLoader from "../components/shared/CircleLoader";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setEmail({ ...email, email: event.target.value });
  };

  const handleSubmit = async (event) => {
    if (!email) {
      setError("Please provide a valid email address.");
      return;
    }

    setIsLoading(true);
    try {
      await axios.post("/api/v1/users/forgot-password", email);
      setEmailSent(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setError(
        "This email address is either not associated with an account or its a Google account."
      );
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: 30,
          fontWeight: "bold",
          color: "#27ae60",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        valet pro
      </div>
      <div>
        {emailSent ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "50px",
              color: "green",
            }}
          >
            Please follow the instructions sent to your email.
            <div style={{ marginTop: "26px", color: "navy" }}>
              <a href="/login">Go Back to Log In</a>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{ textAlign: "center", fontWeight: 700, fontSize: "22px" }}
            >
              Forgot Password?
            </div>
            <p style={{ width: "280px", textAlign: "center" }}>
              Tell us the email address associated with your Valet Pro account,
              and we’ll send you an email with a link to reset your password.
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="E-mail"
                variant="outlined"
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Enter your email here..."
                required
                disabled={isLoading}
                style={{ width: "280px", marginTop: "20px" }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isLoading ? (
                <CircleLoader />
              ) : (
                <ForgotButton onClick={() => handleSubmit()}>
                  Send Link
                </ForgotButton>
              )}
            </div>
            <div
              style={{
                textAlign: "center",
                color: "red",
                fontSize: "12px",
                width: "280px",
                marginTop: "10px",
              }}
            >
              {error && error}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const ForgotButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default ForgotPassword;
