import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
  },
  pageDiv: {
    display: "flex",
    height: "120px",
    width: "120px",
    margin: "25px 0",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "15px",
  },
  rootPageDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

const CustomerHomePage = ({ history }) => {
  const classes = useStyles();

  const goToPage = (page) => {
    history.push(page);
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={classes.rootPageDiv}>
          <div
            onClick={() => goToPage("/locations")}
            className={classes.pageDiv}
          >
            Find Parking
          </div>
          <div
            onClick={() => goToPage("/bookings")}
            className={classes.pageDiv}
          >
            My Bookings
          </div>
        </div>
        <div className={classes.rootPageDiv}>
          <div className={classes.pageDiv} onClick={() => goToPage("/help")}>
            Need Help?
          </div>
          <div
            className={classes.pageDiv}
            onClick={() => goToPage("/contact-us")}
          >
            Contact Us
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default withRouter(CustomerHomePage);
