import React from "react";
import PaypalButton from "./PaypalButton";
import ValetService from "../services/ValetService";

const CLIENT = {
  sandbox:
    "ASNGeWZXbht3LORkoJIa74XMCUbX-P-KpizixcKPWLL5299lAyrdEslU9XSB-bhgVpe8lfyaEHQXClNv",
  production:
    "AUaVx4l2tZ_eN8Yp2z1BQozFnZLzBJRDlbqeM-8Ot7F7utLTtzmBP3JUsfySxhQFS5cuoY7DLlSjNk2A",
};

const ENV = "production";

const GeneralPaypalComponent = ({ valet, handleNext, setPaymentError }) => {
  const onApprove = async () => {
    try {
      const valetService = new ValetService();
      await valetService.updateStatus(valet._id, "paid");
      setTimeout(() => {
        handleNext();
      }, 300);
    } catch (err) {
      console.error(err);
    }
  };

  const onSuccess = async (payment) => {
    const tokObj = {
      ppSuc: payment.paid,
      ppCanc: payment.cancelled,
      ppAmount: (valet.totalCost / 100).toFixed(2),
    };

    if (tokObj.ppSuc) {
      await onApprove();
    }
  };

  const onError = (error) => {
    setPaymentError("Looks like something went wrong, please try again");
    console.log("Erroneous payment OR failed to load script!", error);
  };

  const onCancel = (data) => console.log("Cancelled payment!", data);

  return (
    <div>
      <PaypalButton
        client={CLIENT}
        env={ENV}
        commit={true}
        currency={"USD"}
        total={valet.totalCost / 100}
        onSuccess={onSuccess}
        onError={onError}
        onCancel={onCancel}
      />
    </div>
  );
};

export default GeneralPaypalComponent;
