import React, { useEffect, useState } from "react";
import ValetService from "../../services/ValetService";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import ParkIcon from "@material-ui/icons/LocalParking";
import { useLocation } from "react-router-dom";
import { formatPrice } from "../../utils/FrontEndHelpers";
import GeneralPaypalComponent from "../../components/GeneralPaypalComponent";
import GeneralPaymentWalletComponent from "../../components/GeneralPaymentWalletComponent";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    width: "280px",
  },
  mapDiv: {
    height: "160px",
    borderRadius: "15px",
  },
  locationNameDiv: {
    fontSize: "20px",
    textAlign: "center",
    marginTop: "15px",
    fontWeight: "bold",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    alignItems: "center",
    boxShadow: "0 8px 24px rgb(149 157 165 / 20%)",
    width: 130,
    height: 130,
  },
  cardsDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  paymentButtonDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0",
    margin: "10px 0",
  },
  subTitle: {
    fontSize: "12px",
    color: "gray",
  },
  iconDiv: {
    margin: "20px 0",
    backgroundColor: "#000",
    height: 28,
    width: 40,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ValetPayments = ({ state }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [valet, setValet] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const search = useLocation().search;
  const params = new URLSearchParams(search);

  const getValetDetails = async (valetId) => {
    setIsLoading(true);
    let isMounted = true;
    try {
      const valetService = new ValetService();
      const data = await valetService.getByPhoneNumber(valetId);

      if (isMounted) {
        setValet(data.data.valet);
      }

      // reload window
      // window.location.reload(false);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const goToReceipt = () => {
    history.push("/valet/receipt?pn=" + valet.phoneNumber);
  };

  // create new promise function
  // const reload = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     window.location.reload();
  //     resolve();
  //   }, 300);
  // });

  useEffect(() => {
    setErrorMessage("");

    // reload();

    // get query param from url
    const pn = params.get("pn");

    // if (state?.valet) {
    //   setValet(state.valet);
    // }

    // if (!pn) {
    //   window.history.back();
    // } else {
    getValetDetails(pn);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.mainDiv}>
      <div style={{ marginBottom: "17px", marginTop: 180 }}>
        <h1
          style={{
            color: "#27AE60",
            textAlign: "center",
          }}
        >
          OPS Valet Payment
        </h1>
        <div
          style={{ marginTop: 20, textTransform: "capitalize" }}
          className={classes.locationNameDiv}
        >
          {valet.locationName}
        </div>
        {/* <div style={{ textAlign: "center", fontSize: "10px", color: "gray" }}>
          {valet.locationAddress}
        </div> */}
      </div>
      <div className={classes.cardsDiv}>
        <div className={classes.card}>
          <div className={classes.iconDiv}>
            <ParkIcon style={{ color: "#fff", fontSize: "medium" }} />
          </div>
          <div style={{ fontWeight: 900 }}>#{valet.ticketNumber}</div>
          <div className={classes.subTitle}>Ticket Number</div>
        </div>
        <div className={classes.card}>
          <div className={classes.iconDiv}>
            <MoneyIcon style={{ color: "#fff", fontSize: "19px" }} />
          </div>
          <div style={{ fontWeight: "bold" }}>
            {formatPrice(valet.totalCost / 100)}
          </div>
          <div className={classes.subTitle}>Est. Total</div>
        </div>
      </div>
      <div className={classes.paymentButtonDiv}>
        {valet && !valet?.paid && valet.totalCost > 0 ? (
          <>
            <GeneralPaymentWalletComponent
              handleNext={goToReceipt}
              valet={valet}
              setPaymentError={setPaymentError}
            />
            <GeneralPaypalComponent
              handleNext={goToReceipt}
              valet={valet}
              setPaymentError={setPaymentError}
            />
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              fontWeight: 900,
              fontSize: 30,
              marginTop: 50,
            }}
          >
            Ticket Paid!
          </div>
        )}
      </div>
      <div>
        {paymentError && (
          <div style={{ textAlign: "center", fontSize: "12px", color: "red" }}>
            {paymentError}
          </div>
        )}

        {errorMessage && (
          <div style={{ textAlign: "center", fontSize: "12px", color: "red" }}>
            {errorMessage}
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {valet?.paid ? (
          <button
            disabled={isLoading}
            style={{ width: "100%", backgroundColor: "#27AE60" }}
            onClick={() =>
              history.push("/valet/receipt?pn=" + valet?.phoneNumber)
            }
          >
            Receipt
          </button>
        ) : (
          <button
            disabled={isLoading}
            style={{
              width: "100%",
              color: "black",
              backgroundColor: "transparent",
              border: "1px solid black",
            }}
            onClick={() => window.history.back()}
          >
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default ValetPayments;
