import React, { useEffect, useState } from "react";
import {
  Button,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import ValetService from "../../services/ValetService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontSize: 40,
    fontWeight: "bold",
    color: "#27ae60",
    justifyContent: "center",
    margin: "30px 0",
    textDecoration: "underline",
  },
  buttonStyle: {
    width: "100%",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: "#27ae60",
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "8px",
    // breakpoint
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      //   gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  wFull: {
    width: "100%",
  },
  plateInput: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  mb: {
    marginBottom: "16px",
  },
  mt: {
    marginTop: "16px",
  },
}));

const EditValet = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [shouldUpdate, setShouldUpdate] = useState(false); // TODO: add here for the parked by and parked location
  const [values, setValues] = useState({
    _id: "",
    phoneNumber: "",
    plateNumber: "",
    ticketNumber: "",
    state: "",
    status: "",
    make: "",
    model: "",
    color: "",
    description: "",
    isHourly: false,
    firstName: "",
    lastName: "",
    email: "",
    totalCost: "",
  });

  const updateValet = async (id) => {
    setIsLoading(true);
    try {
      const valetService = new ValetService();
      await valetService.update(id, values);
      window.location.href = "/valet";
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getValetDetails = async (valetId) => {
    setIsLoading(true);
    try {
      const valetService = new ValetService();
      const data = await valetService.getById(valetId);
      setValues(data.data.valet);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // get query param from url
    const urlParams = new URLSearchParams(window.location.search);
    const valetId = urlParams.get("valetId");
    if (valetId) {
      getValetDetails(valetId);
    } else {
      window.history.back();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginBottom: 50 }}>
      <div className={classes.root}>Edit</div>
      {/* // full form start */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ gap: 16, display: "flex", width: "100%" }}>
          <TextField
            style={{ width: "100%" }}
            label="Ticket Number..."
            type="number"
            name="ticketNumber"
            disabled={isLoading}
            value={values.ticketNumber}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            inputProps={{
              maxLength: 10,
            }}
            required
            variant="outlined"
            onChange={(e) =>
              setValues({
                ...values,
                ticketNumber: e.target.value.toUpperCase(),
              })
            }
          />
          <TextField
            style={{ width: "100%" }}
            label="Phone Number..."
            type="number"
            name="phoneNumber"
            required
            disabled={isLoading}
            value={values.phoneNumber}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            inputProps={{
              maxLength: 10,
            }}
            variant="outlined"
            onChange={(e) =>
              setValues({
                ...values,
                phoneNumber: e.target.value.toUpperCase(),
              })
            }
          />
        </div>

        <div style={{ display: "flex", gap: 16, width: "100%" }}>
          <TextField
            label={`Parked By`}
            variant="outlined"
            style={{ width: "100%" }}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentIndIcon />{" "}
                </InputAdornment>
              ),
            }}
            placeholder={`John Doe`}
            onChange={(e) => setValues({ ...values, parkedBy: e.target.value })}
            value={values.parkedBy}
            disabled={isLoading}
          />{" "}
          <TextField
            label={`Parked Location`}
            variant="outlined"
            style={{ width: "100%" }}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalParkingIcon />{" "}
                </InputAdornment>
              ),
            }}
            placeholder={`FSU Lot 1`}
            onChange={(e) =>
              setValues({ ...values, parkedLocation: e.target.value })
            }
            value={values.parkedLocation}
            disabled={isLoading}
          />{" "}
        </div>

        {/* // customer personal Info */}
        <div className={classes.flexRow}>
          <TextField
            label="Customer First Name..."
            type="text"
            name="firstName"
            className={classes.wFull}
            disabled={isLoading}
            value={values.firstName}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            required
            variant="outlined"
            onChange={(e) =>
              setValues({ ...values, firstName: e.target.value })
            }
          />
          <TextField
            label="Customer Last Name..."
            type="text"
            name="lastName"
            className={classes.wFull}
            disabled={isLoading}
            value={values.lastName}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            variant="outlined"
            onChange={(e) => setValues({ ...values, lastName: e.target.value })}
          />
        </div>

        {/* // customer vehicle info */}
        <div style={{ display: "flex", width: "100%", gap: 16 }}>
          <TextField
            label={"License Plate Number"}
            name="vehiclePlate"
            required
            className={classes.plateInput}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            inputProps={{
              maxLength: 8,
              style: { textTransform: "uppercase" },
            }}
            variant="outlined"
            onChange={(e) =>
              setValues({
                ...values,
                plateNumber: e.target.value.toUpperCase(),
              })
            }
            value={values.plateNumber}
            disabled={isLoading}
          />{" "}
          <TextField
            label={"License State"}
            name="vehicleState"
            classes={{ root: classes.plateStateInput }}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            inputProps={{
              maxLength: 2,
              style: { textTransform: "uppercase" },
            }}
            required
            variant="outlined"
            onChange={(e) =>
              setValues({
                ...values,
                state: e.target.value.toUpperCase(),
              })
            }
            value={values.state}
            disabled={isLoading}
          />{" "}
        </div>

        {/* // vehicle dets */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 16,
          }}
        >
          <TextField
            label="Car Make..."
            style={{ width: "100%" }}
            type="text"
            name="vehicleMake"
            disabled={isLoading}
            value={values.make}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            required
            variant="outlined"
            onChange={(e) => setValues({ ...values, make: e.target.value })}
          />
          <TextField
            label="Model..."
            style={{ width: "100%" }}
            type="text"
            name="vehicleModel"
            disabled={isLoading}
            value={values.model}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            variant="outlined"
            onChange={(e) => setValues({ ...values, model: e.target.value })}
          />
          <TextField
            label="Color..."
            style={{ width: "100%" }}
            type="text"
            name="vehicleColor"
            disabled={isLoading}
            value={values.color}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            required
            variant="outlined"
            onChange={(e) => setValues({ ...values, color: e.target.value })}
          />
        </div>
      </div>

      <div className={classes.buttonDiv}>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          disableElevation
          onClick={() => updateValet(values._id)}
          disabled={isLoading}
        >
          Update
        </Button>
      </div>
      <div>
        {errorMessage && (
          <div style={{ color: "red", textAlign: "center", marginTop: 20 }}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditValet;
