import APIkit from ".";

class SessionService {
  getAll() {
    return APIkit.get("/api/v1/sessions");
  }

  me() {
    return APIkit.get("/api/v1/sessions/me");
  }

  get(id) {
    return APIkit.get(`/api/v1/sessions/${id}`);
  }

  memberCode(data) {
    return APIkit.post(`/api/v1/sessions/code`, data);
  }

  getVehicleStatus(data) {
    return APIkit.post("/api/v1/sessions/status", data);
  }

  create(data) {
    return APIkit.post("/api/v1/sessions", data);
  }

  addSession(data) {
    return APIkit.post("/api/v1/sessions/add", data);
  }

  update(id, data) {
    return APIkit.patch(`/api/v1/sessions/${id}`, data);
  }

  delete(id) {
    return APIkit.delete(`/api/v1/sessions/${id}`);
  }

  findById(title) {
    return APIkit.get(`/api/v1/sessions?id=${title}`);
  }
}

export default SessionService;
