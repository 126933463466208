import React from "react";
import { isAuth } from "../utils/AuthHelpers/AuthCookieHandler";
import CustomerHomePage from "./customer/CustomerHomePage";
// import VendorHomePage from "./management/VendorHomePage";
import LoginPage from "./LoginPage";
import EnforcerPage from "./enforcer/EnforcerPage";
// import OdogwuPage from "./management/OdogwuPage";
import Spots from "./side/Spots";

const HomePage = ({ history }) => {
  const showHomePage = (role) => {
    switch (role) {
      case "vendor":
        return <Spots />;
      case "customer":
        return <CustomerHomePage />;
      case "odogwu":
        return <Spots />;
      case "enforcer":
        return <EnforcerPage />;
      case "valet":
        return <Spots />;
      case null:
        return <LoginPage />;
      default:
        break;
    }
  };

  return showHomePage(isAuth() && isAuth().role);
};

export default HomePage;
