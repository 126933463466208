import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SessionCheckoutPage from "./SessionCheckoutPage";
import ZoneService from "../services/ZoneService";
import CircleLoader from "../components/shared/CircleLoader";

const ProxyCheckout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [zone, setZone] = useState([]);
  const { locationId, zoneId } = useParams();

  const getZoneInfo = async () => {
    setIsLoading(true);

    const zoneInfo = {
      locationId,
      zoneId,
    };

    const zoneService = new ZoneService();
    const {
      data: { data },
    } = await zoneService.get(zoneInfo);

    setZone(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getZoneInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {Object.keys(zone).length > 0 ? (
        <SessionCheckoutPage isLoading={isLoading} zoneObject={zone} />
      ) : (
        <CircleLoader />
      )}
    </div>
  );
};

export default ProxyCheckout;
