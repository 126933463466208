import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { isAuth, signout } from "../utils/AuthHelpers/AuthCookieHandler";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { capitalize } from "../utils/FrontEndHelpers";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Profile = () => {
  const history = useHistory();
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    role: "",
    email: "",
    password: "",
    buttonText: "Update Profile",
    error: "",
    success: "",
    disable: false,
    isLoading: false,
  });

  const { name, email, password, buttonText, disable, isLoading, role } =
    values;

  useEffect(() => {
    loadProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProfile = async () => {
    const { role, name, email } = isAuth();
    setValues({ ...values, role, name, email });
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, buttonText: "Updating...", isLoading: true });

    console.log("doing something");
  };

  const logout = () => {
    signout(() => {
      history.push("/login");
      window.location.reload(false);
    });
  };

  return (
    <div className={classes.mainDiv}>
      <div>
        <div>
          <div
            style={{
              textAlign: "center",
              margin: "35px 0",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            Profile
          </div>
        </div>
      </div>
      <div className="form-group">
        <TextField
          label="E-mail"
          type="email"
          name="email"
          disabled={true}
          placeholder="Email"
          value={email}
          required
          variant="outlined"
        />
      </div>
      <div className="form-group">
        <TextField
          label="Role"
          type="text"
          name="role"
          disabled={true}
          placeholder="Role"
          value={capitalize(role)}
          required
          variant="outlined"
        />
      </div>
      <div className="form-group">
        <TextField
          label="Name"
          onChange={handleChange("name")}
          type="text"
          name="name"
          disabled={disable}
          value={capitalize(name)}
          required
          variant="outlined"
        />
      </div>
      <div className="form-group">
        <TextField
          label="Password"
          onChange={handleChange("password")}
          type="password"
          name="password"
          disabled={disable}
          value={password}
          variant="outlined"
        />
      </div>

      <div className={classes.buttonDiv}>
        <UpdateButton
          variant="contained"
          color="primary"
          disabled={disable}
          onClick={handleSubmit}
          style={{ color: "#fff", width: "280px" }}
          className={classes.button}
        >
          {buttonText}
        </UpdateButton>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
        <DeleteButton disabled={isLoading} onClick={logout}>
          Log Out
        </DeleteButton>
      </div>
    </div>
  );
};

const UpdateButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

const DeleteButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #e65a4d;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #eb7a70;
  }
`;

export default Profile;
