import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import ValetService from "../services/ValetService";

const useOptions = (paymentRequest) => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "35px",
        },
      },
    }),
    [paymentRequest]
  );

  return options;
};

const GeneralPaymentWalletComponent = ({
  valet,
  handleNext,
  setPaymentError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: `Payment for Location: ${valet.locationName}, Ticket Nunmber: #${valet.ticketNumber}`,
        amount: valet.totalCost,
      },
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on("paymentmethod", async ({ complete, paymentMethod }) => {
      const {
        error: backendError,
        clientSecret,
        ...unsuable
      } = await axios.post("/api/v1/billing/valet/stripe-intent", {
        valet,
      });

      if (backendError) {
        console.log(backendError.message);
        setPaymentError("Looks like something went wrong, please try again");
        return;
      }

      // update valet status to paid if successful
      const valetService = new ValetService();
      try {
        await valetService.updateStatus(valet._id, "paid");
      } catch (error) {
        console.log(Error(error));
      }

      const { paymentIntent, error: stripeError } =
        await stripe.confirmCardPayment(
          unsuable.data.clientSecret,
          {
            payment_method: paymentMethod.id,
          },
          { handleActions: false }
        );

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        console.log("stripeError", stripeError.message);
        setPaymentError(stripeError.message);
        return;
      }

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      complete("success");
      handleNext();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements, handleNext]);

  const options = useOptions(paymentRequest);

  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement
          className="PaymentRequestButton"
          options={options}
        />
      )}
    </>
  );
};

export default withRouter(GeneralPaymentWalletComponent);
