import React, { useState, useEffect } from "react";
import History from "../components/History";
import { makeStyles } from "@material-ui/core/styles";
import SessionService from "../services/SessionService";
import CircleLoader from "../components/shared/CircleLoader";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  status: {
    padding: "6px",
    cursor: "pointer",
    borderRadius: "20px",
    border: "1px solid",
    margin: "0 2px",
  },
}));

const HistoryPage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const getHistory = async () => {
    setIsLoading(true);
    const sessionService = new SessionService();
    const { data } = await sessionService.me();
    setHistory(data.sessions);
    setIsLoading(false);
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div className={classes.mainDiv}>
      <div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Booking History
        </div>
      </div>
      <div>
        {isLoading ? (
          <CircleLoader />
        ) : history.length > 0 ? (
          history.map((booking) => (
            <History key={Math.random()} booking={booking} />
          ))
        ) : (
          <div>You currently do not have any bookings...</div>
        )}
      </div>
    </div>
  );
};

export default HistoryPage;
