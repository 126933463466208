import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

// Pages
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ErrorPage from "./pages/ErrorPage";
import LocationPage from "./pages/LocationPage";
import LocationAddPage from "./pages/LocationAddPage";
import ZonePage from "./pages/ZonePage";
import ZoneAddPage from "./pages/ZoneAddPage";
import ProxyCheckout from "./pages/ProxyCheckout";
import HomePage from "./pages/HomePage";
import Profile from "./pages/Profile";
import ContactPage from "./pages/ContactPage";
import HistoryPage from "./pages/HistoryPage";
import LocationEditPage from "./pages/LocationEditPage";
import ZoneEditPage from "./pages/ZoneEditPage";
import RevenuePage from "./pages/management/RevenuePage";
import HelpPage from "./pages/HelpPage";
import SubbedRouteWrapper from "./components/shared/SubbedRouteWrapper";
import VendorRouteWrapper from "./components/shared/VendorRouteWrapper";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import EnforceRouteWrapper from "./components/shared/EnforceRouteWrapper";
import ServiceLocations from "./pages/enforcer/ServiceLocations";
import EnforcementPage from "./pages/management/EnforcementPage";
import ActivateAccountPage from "./pages/auth/ActivateAccountPage";
import SearchVehiclePage from "./pages/enforcer/SearchVehiclePage";
import LotRevenuePage from "./pages/management/LotRevenuePage";
import AdminRouteWrapper from "./components/shared/AdminRouteWrapper";
import UpdateRevenuePage from "./pages/management/UpdateRevenuePage";
import Navbar from "./components/shared/Navbar";
import Revenues from "./pages/side/Revenues";
import Reports from "./pages/side/Reports";
import MemberCheckoutPage from "./pages/sessionflow/MemberCheckoutPage";
import MemberReceiptPage from "./pages/sessionflow/MemberReceiptPage";
import ValetPage from "./pages/ValetPage";
import CreateValet from "./pages/valet/CreateValet";
import ValetDetail from "./pages/valet/ValetDetail";
import EditValet from "./pages/valet/EditValet";
import ValetPayPage from "./pages/ValetPayPage";
import ValetPayments from "./pages/valet/ValetPayments";
import ValetReceipt from "./pages/valet/ValetReceipt";
import ValetRouteWrapper from "./components/shared/ValetRouteWrapper";
import AddLotSession from "./pages/management/AddLotSession";
import ManagementPage from "./pages/management/ManagementPage";
import TeamComponent from "./components/TeamComponent";
import ParkingDetails from "./pages/valet/ParkingDetails";

const App = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <div className="main-div">
        <Switch>
          {/* // basic public routes */}
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/contact-us" component={ContactPage} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/reset/:token" component={ResetPassword} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/valet/review" component={ValetPayPage} />
          <Route exact path="/valet/pay" component={ValetPayments} />
          <Route exact path="/valet/receipt" component={ValetReceipt} />
          <Route
            exact
            path="/locations/:locationId/zones/:zoneId"
            component={ProxyCheckout}
          />
          <Route
            exact
            path="/locations/:locationId/zones/:zoneId/members"
            component={MemberCheckoutPage}
          />
          <Route
            exact
            path="/locations/:locationId/zones/:zoneId/members/receipt"
            component={MemberReceiptPage}
          />
          <Route
            exact
            path="/auth/member/activate/:token"
            component={ActivateAccountPage}
          />
          <Route
            exact
            path="/locations/:locationId/zones"
            component={ZonePage}
          />
          <Navbar>
            {/* // subbbed routes */}
            <SubbedRouteWrapper exact path="/" component={HomePage} />
            <SubbedRouteWrapper exact path="/home" component={HomePage} />
            <SubbedRouteWrapper exact path="/home-old" component={HomePage} />
            <SubbedRouteWrapper exact path="/profile" component={Profile} />
            <SubbedRouteWrapper
              exact
              path="/bookings"
              component={HistoryPage}
            />

            {/* // subbed and vendor routes */}
            <SubbedRouteWrapper exact path="/dashboard" component={HomePage} />
            <SubbedRouteWrapper exact path="/team" component={TeamComponent} />
            <VendorRouteWrapper exact path="/revenues" component={Revenues} />
            <VendorRouteWrapper exact path="/reports" component={Reports} />
            <ValetRouteWrapper exact path="/valet" component={ValetPage} />
            <ValetRouteWrapper
              exact
              path="/valet/create"
              component={CreateValet}
            />
            <ValetRouteWrapper
              exact
              path="/valet/details"
              component={ValetDetail}
            />
            <ValetRouteWrapper
              exact
              path="/valet/details/edit"
              component={EditValet}
            />
            <ValetRouteWrapper
              exact
              path="/valet/details/park"
              component={ParkingDetails}
            />

            {/* // vendor routes */}
            <VendorRouteWrapper exact path="/revenue" component={RevenuePage} />
            <VendorRouteWrapper
              exact
              path="/locations-vendor"
              component={LocationPage}
            />

            <VendorRouteWrapper
              exact
              path="/locations/:lotId/details"
              component={LotRevenuePage}
            />
            {/* <VendorRouteWrapper
              exact
              path="/revenue/lot/:lotId"
              component={LotRevenuePage}
            />
            <VendorRouteWrapper
              exact
              path="/revenue/lot/:lotId/new"
              component={AddLotSession}
            /> */}
            <VendorRouteWrapper
              exact
              path="/locations/:locationId/edit"
              component={LocationEditPage}
            />
            <VendorRouteWrapper
              exact
              path="/locations/add"
              component={LocationAddPage}
            />
            <VendorRouteWrapper
              exact
              path="/team"
              component={EnforcementPage}
            />
            <VendorRouteWrapper
              exact
              path="/locations/:locationId/new/zone"
              component={ZoneAddPage}
            />
            <VendorRouteWrapper
              exact
              path="/locations/:locationId/zones/:zoneId/edit"
              component={ZoneEditPage}
            />

            {/* // enforcer routes */}
            <EnforceRouteWrapper
              exact
              path="/service/locations"
              component={ServiceLocations}
            />
            <EnforceRouteWrapper
              exact
              path="/service/locations/:locationId/search"
              component={SearchVehiclePage}
            />

            {/* // ogodwu routes */}
            <AdminRouteWrapper
              exact
              path="/revenue/update"
              component={UpdateRevenuePage}
            />
            <VendorRouteWrapper
              exact
              path="/management"
              component={ManagementPage}
            />
          </Navbar>
          <ErrorPage />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
