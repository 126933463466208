import axios from "axios";

class VehicleService {
  get(data) {
    return axios.post("/api/v1/vehicles/get", data);
  }

  getVehicleStatus(license) {
    return axios.get(`/api/v1/vehicles/${license}`);
  }
}

export default VehicleService;
