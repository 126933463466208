import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { isAuth } from "../utils/AuthHelpers/AuthCookieHandler";

const ServiceLocation = ({ locations }) => {
  const history = useHistory();

  const goToServiceSearchPage = (location) => {
    history.push({
      pathname: `/service/locations/${location._id}/search`,
      state: location,
    });
  };

  return (
    <div>
      {locations && locations.length > 0 ? (
        locations.map((loc) => {
          return (
            <div
              key={loc._id}
              style={{
                padding: "14px",
                borderRadius: "10px",
                margin: "20px",
                cursor: "pointer",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3 style={{ textTransform: "capitalize" }}>{loc.name}</h3>
              <p>{loc.address}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent:
                    isAuth().role === "customer" ? "center" : "space-between",
                }}
              >
                <ActionButton
                  style={{
                    width: 280,
                  }}
                  onClick={() => goToServiceSearchPage(loc)}
                >
                  Search Vehicles Here
                </ActionButton>
              </div>
            </div>
          );
        })
      ) : (
        <div>We cannot find any parking locations...</div>
      )}
    </div>
  );
};

const ActionButton = styled.button`
  height: 50px;
  width: 90px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default ServiceLocation;
