import React from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { cl } from "../../theme/colors";
import { capitalize, formatPrice } from "../../utils/FrontEndHelpers";

const LocationStatCard = ({ lot, goToLotRevenue, revenue }) => {
  const calculatePercentageChange = () => {
    if (revenue.currentMonth != null && revenue.lastMonth != null) {
      if (revenue.lastMonth === 0) {
        return revenue.currentMonth > 0 ? 100 : 0;
      }
      return (
        ((revenue.currentMonth - revenue.lastMonth) / revenue.lastMonth) * 100
      );
    }
    return 0;
  };

  return (
    <div
      onClick={() => goToLotRevenue(lot)}
      style={{
        margin: "20px 0",
        minWidth: 250,
        // border: `1px solid ${cl.nonary}`,
        cursor: "pointer",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: 15,
        padding: 15,
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            height: 36,
            width: 36,
            borderRadius: "10px",
            backgroundColor: cl.blue1,
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            border: "none",
          }}
        >
          <LocationOnIcon
            style={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              marginRight: 15,
              color: cl.white,
            }}
            fontSize="small"
          />
        </div>
        <div>
          <div style={{ fontWeight: 800 }}>{capitalize(lot.name)}</div>
          <div style={{ color: cl.nonary, fontSize: 12 }}>{lot.address}</div>
        </div>
      </div>
      <div
        style={{
          margin: "20px 0 0 0",
          fontWeight: 800,
          color: cl.tertiary,
          fontSize: 25,
          textAlign: "center",
        }}
      >
        {formatPrice(Number(revenue?.currentMonth / 100))}
      </div>
      <div
        style={{
          margin: "10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 13,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            color: cl.secondary,
          }}
        >
          {revenue?.changePercentage === "Infinity" ||
          revenue?.changePercentage === -0 ? (
            "100%+"
          ) : revenue?.changePercentage > 0 ? (
            <div
              style={{
                display: "flex",
                gap: "5px",
                color: cl.secondary,
              }}
            >
              {calculatePercentageChange()}%
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "5px",
                color: cl.septenary,
              }}
            >
              {calculatePercentageChange()}%
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationStatCard;
