import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { capitalize } from "../utils/FrontEndHelpers";
import HomeIcon from "@material-ui/icons/LocationOn";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    width: "280px",
    padding: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "10px",
    margin: "15px 0",
    "&:hover": { cursor: "pointer" },
  },
  locationCost: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0",
    fontWeight: "bold",
  },
  address: {
    display: "flex",
    fontSize: "10px",
    color: "gray",
    margin: "15px 0",
  },
  timeDiv: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
  },
  timeDetails: {
    display: "flex",
    fontSize: "12px",
    justifyContent: "space-between",
  },
}));

const Revenue = ({ location, goToLotRevenue }) => {
  const classes = useStyles();
  return (
    <div
      key={location.id}
      className={classes.mainDiv}
      onClick={() => goToLotRevenue(location)}
    >
      <div className={classes.locationCost}>
        <div>{capitalize(location.locationName)}</div>
        <div>${location.totalCostPlus.toFixed(2)}</div>
      </div>
      <div className={classes.address}>
        <div style={{ margin: "0 10px 0 0" }}>
          <HomeIcon fontSize="small" />
        </div>
        <div style={{ width: "180px" }}>
          <div>{location.locationAddress}</div>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
