import React, { useEffect, Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import TeamTable from "../../components/TeamTable";
import UserService from "../../services/UserService";
import AddMemberModal from "../../components/modals/AddMemberModal";
import TeamComponent from "../../components/TeamComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: theme.palette.background.paper,
    // backgroundColor: "#fff",
  },
  mainTable: {
    width: "70vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflowX: "scroll",
      border: "1px solid #ccc",
      borderRadius: 10,
    },
  },
}));

const EnforcementPage = () => {
  const classes = useStyles();
  const [members, setMembers] = useState("");

  const getTeamMembers = async () => {
    const userService = new UserService();
    const { data } = await userService.getEnforcement();
    setMembers(data.users);
  };

  useEffect(() => {
    getTeamMembers();
    // console.log("data", members && members);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <h1 style={{ color: "#27ae60", textAlign: "center" }}>Team Members</h1>
        <Fragment>
          <AddMemberModal getTeamMembers={getTeamMembers} />
        </Fragment>
      </div>
      <div className={classes.mainTable}>
        {members && members?.length > 0 ? (
          <TeamComponent getTeamMembers={getTeamMembers} members={members} />
        ) : (
          <div style={{ textAlign: "center" }}>
            Looks like you have not added any members yet.
          </div>
        )}
      </div>
    </div>
  );
};

export default EnforcementPage;
