import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
  },
  pageDiv: {
    display: "flex",
    height: "120px",
    width: "280px",
    margin: "25px 0",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "15px",
  },
  rootPageDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

const EnforcerPage = ({ history }) => {
  const classes = useStyles();

  const goToPage = (page) => {
    history.push(page);
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={classes.rootPageDiv}>
          <div
            onClick={() => goToPage("/service/locations")}
            className={classes.pageDiv}
          >
            My Service Locations
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default withRouter(EnforcerPage);
