import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LocationService from "../../services/LocationService";
import ServiceLocation from "../../components/ServiceLocation";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "50px",
  },
}));

const ServiceLocations = () => {
  const classes = useStyles();
  const [locations, setLocations] = useState([]);

  const getMyServiceLocations = async () => {
    // get locations associated with user here
    const locationService = new LocationService();
    const { data } = await locationService.getMyServiceLocations();
    setLocations(data.data.locations);
  };

  useEffect(() => {
    getMyServiceLocations();
    // console.log("data", members && members);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <h1 style={{ color: "#27ae60" }}>Locations Servicing</h1>
      </div>
      <div>
        {locations.length > 0 ? (
          <ServiceLocation locations={locations} />
        ) : (
          <div style={{ textAlign: "center" }}>
            Looks like you are not servicing any locations yet.
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceLocations;
