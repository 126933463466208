import React, { useEffect, useState } from "react";
import BillingService from "../../services/BillingService";
import { makeStyles } from "@material-ui/core/styles";
import Revenue from "../../components/Revenue";
import CircleLoader from "../../components/shared/CircleLoader";
import { useHistory } from "react-router-dom";
import { months } from "../../utils/FrontEndHelpers";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  status: {
    padding: "6px",
    cursor: "pointer",
    borderRadius: "20px",
    border: "1px solid",
    margin: "0 2px",
  },
  month: {
    padding: "5px",
    cursor: "pointer",
    borderRadius: "20px",
    border: "1px solid",
    margin: "5px",
    maxWidth: "90px",
    textAlign: "center",
    "&:hover": { backgroundColor: "#f5f5f5" },
  },
  monthGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "5px",
    margin: "0 15px",
  },
}));

const RevenuePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [locationRevenue, setLocationRevenue] = useState([]);

  const getRevenue = async (month = "") => {
    setIsLoading(true);
    setSelectedMonth(month);
    const billingService = new BillingService();
    const { data } = await billingService.getRevenue({
      month: selectedMonth,
      date: new Date(),
    });
    setLocationRevenue(data.totalLocationRevenue);
    setIsLoading(false);
  };

  const goToLotRevenue = (lot) => {
    console.log(lot);
    history.push({
      pathname: `/revenue/lot/${lot.locationId}`,
      state: lot,
    });
  };

  useEffect(() => {
    getRevenue();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.mainDiv}>
      <div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Location Revenues: {selectedMonth ? selectedMonth : "Month To Date"}
        </div>
        <div className={classes.monthGrid}>
          {months.map((month, idx) => {
            return (
              <div
                className={classes.month}
                onClick={() => getRevenue(month)}
                key={idx}
              >
                {month}
              </div>
            );
          })}
        </div>
      </div>
      <div className="rev-cards">
        {isLoading ? (
          <CircleLoader />
        ) : locationRevenue.length > 0 ? (
          locationRevenue.map((location, idx) => (
            <Revenue
              goToLotRevenue={goToLotRevenue}
              key={idx}
              location={location}
            />
          ))
        ) : (
          <div>You currently do not have any Locations activated...</div>
        )}
      </div>
    </div>
  );
};

export default RevenuePage;
