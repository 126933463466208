import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { getLocalStorage } from "../../utils/AuthHelpers/AuthCookieHandler";
import { isEmpty } from "../../utils/FrontEndHelpers";
import ContactService from "../../services/ContactService";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(() => ({
  lineitem: {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    margin: "10px 0px",
  },
  table: {
    minWidth: 650,
  },
}));

const ReceiptPage = ({
  handleNext,
  sessionData,
  zoneObj: { zone, location },
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);
    if (isEmpty(email)) {
      setErrorMessage("Please provide a valid email address");
      return;
    }

    if (isEmpty(name)) {
      setErrorMessage("Please provide a valid name");
      return;
    }

    const receiptObject = {
      email,
      name,
      sessionData,
      locationName: location.name,
      locationAddress: location.address,
      zoneNumber: zone.zoneNumber,
    };

    try {
      const contactService = new ContactService();
      await contactService.sendReceipt(receiptObject);
    } catch (err) {
      setErrorMessage(err.message);
    }

    setIsLoading(false);
    handleNext();
  };

  const format = (num) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(num);
  };

  useEffect(() => {
    const user = getLocalStorage("user");
    if (user) {
      setName(user.name);
      setEmail(user.email);
    } else {
      setName("");
      setEmail("");
    }
  }, []);

  return (
    <div style={{ width: 280 }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          Thank You!
        </div>
        <div style={{ textAlign: "center", textTransform: "capitalize" }}>
          <div style={{ color: "#696969", fontWeight: 600 }}>
            {location.name}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <div style={{ color: "gray", fontSize: 12 }}>{location.address}</div>
        </div>
        <div className={classes.lineitem}>
          <div>Zone</div>
          <div>#{zone.zoneNumber}</div>
        </div>
        <div className={classes.lineitem}>
          <div>Date</div>
          <div>{moment().format("MMMM Do, YYYY")}</div>
        </div>
        <div className={classes.lineitem}>
          <div>Duration</div>
          <div>{zone.isHourly ? `${sessionData.hours} hour` : "1 Day"}</div>
        </div>
        <div className={classes.lineitem}>
          <div>Total</div>
          <div>{format(sessionData.totalCost)}</div>
        </div>
        <div
          style={{ marginTop: "30px", textAlign: "center", fontWeight: "bold" }}
        >
          Email to send receipt
        </div>
        <TextField
          label={"name"}
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
          value={name}
          inputProps={{
            maxLength: 50,
            style: { textTransform: "capitalize" },
          }}
          disabled={isLoading}
        />{" "}
        <TextField
          label={"email"}
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          disabled={isLoading}
        />{" "}
        <div style={{ color: "red", textAlign: "center", height: "20px" }}>
          {errorMessage && errorMessage}
        </div>
        <SendButton onClick={handleSubmit} disabled={isLoading}>
          Send Receipt
        </SendButton>
      </div>
    </div>
  );
};

const SendButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default ReceiptPage;
