import UserService from "../services/UserService";
import LocationService from "../services/LocationService";
import ZoneService from "../services/ZoneService";

export const fetchUser = async () => {
  try {
    const user = new UserService();
    const res = await user.getMe();
    return res.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchLocation = async (locationId) => {
  try {
    const location = new LocationService();
    const res = await location.get(locationId);
    return res.data.data.location;
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchLocations = async (latlng) => {
  try {
    const location = new LocationService();
    const res = await location.getNearby(latlng);
    return res.data.data.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchMyLocations = async () => {
  try {
    const location = new LocationService();
    const res = await location.getMyAll();
    return res.data.data.locations;
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchZone = async (zoneInfo) => {
  try {
    const zone = new ZoneService();
    const {
      data: { data },
    } = await zone.get(zoneInfo);
    return data;
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchZones = async (locationId) => {
  try {
    const zone = new ZoneService();
    const res = await zone.getAll(locationId);
    return res.data.data.zones;
  } catch (err) {
    console.log(err.message);
  }
};

export const setHours = async (hours) => {
  return hours;
};

export const setSession = async (sessionInfo) => {
  return sessionInfo;
};
