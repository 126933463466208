import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import ValetService from "../../services/ValetService";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import ScheduleIcon from "@material-ui/icons/Schedule";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import {
  formatPrice,
  formatUSPhoneNumber,
  isEmpty,
} from "../../utils/FrontEndHelpers";
import { bkGrdColors } from "../ValetPage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontSize: 40,
    fontWeight: "bold",
    color: "#27ae60",
    justifyContent: "center",
    margin: "30px 0",
    textDecoration: "underline",
  },
  buttonStyle: {
    width: "100%",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: "#27ae60",
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    // breakpoint
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      //   gridTemplateColumns: "1fr 1fr 1fr",
    },
    marginBottom: 10,
    marginTop: 17,
  },
  wFull: {
    width: "100%",
  },
  plateInput: {
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  mb: {
    marginBottom: "16px",
  },
  mt: {
    marginTop: "16px",
  },
  statusContainer: {
    // display: "grid",
    // gridAutoFlow: "row",
    // gridTemplateColumns: "1fr 1fr",
    // width: "100%",
    display: "flex",
    gap: "8px",
    color: "black",
    // gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
    // gridTemplateRows: "repeat(auto-fill, 1fr)",
    // gridAutoColumns: "minmax(100px, auto)",

    // breakpoint
    // [theme.breakpoints.down("md")]: {
    //   gridTemplateColumns: "1fr 1fr",
    // },
    [theme.breakpoints.down("sm")]: {
      // gridTemplateColumns: "1fr",
      flexDirection: "column",
    },
  },
  detail: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    fontWeight: 900,
    fontSize: 20,
  },
}));

const valetStatuses = [
  {
    status: "parked",
    label: "CAR PARKED",
    color: "#f39c12",
  },
  {
    status: "ready",
    label: "CAR READY",
    color: "blue",
  },
  {
    status: "paid",
    label: "CASH PAYMENT",
    color: "#27ae60",
  },
  {
    status: "completed",
    label: "Complete Session!",
    color: "white",
  },
];

const ValetDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [valet, setValet] = useState({});
  const [values, setValues] = useState({
    parkedBy: "",
    parkedLocation: "",
  });

  const getColorByStatus = (status) => {
    if (status === "ready") {
      if (valet.paid === true) {
        return bkGrdColors["paid"];
      } else {
        return bkGrdColors["unpaid"];
      }
    } else {
      return bkGrdColors[status];
    }
  };

  const getStatus = (status) => {
    if (status === "ready") {
      if (valet.paid === true) {
        return "paid";
      } else {
        return "unpaid";
      }
    } else {
      return status;
    }
  };

  const getValetDetails = async (valetId) => {
    setIsLoading(true);
    try {
      const valetService = new ValetService();
      const data = await valetService.getById(valetId);
      setValet(data.data.valet);
      if (data.data.valet.parkedBy) {
        setValues({
          parkedBy: data.data.valet.parkedBy,
          parkedLocation: data.data.valet.parkedLocation,
        });
      }
    } catch (err) {
      console.log("[VALET_DETAILS_ERROR]", err);
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateValetStatus = async (valet, status) => {
    setIsLoading(true);
    const valetId = valet._id;

    if (!valet.parkedBy && valet.status === "entry") {
      history.push(`/valet/details/park?valetId=${valetId}`);
      setIsLoading(false);
      return;
    }

    if (values.parkedBy) {
      if (isEmpty(values?.parkedBy)) {
        setErrorMessage("Please enter who parked the car");
        setIsLoading(false);
        return;
      }

      if (isEmpty(values?.parkedLocation)) {
        setErrorMessage("Please enter where the car is parked");
        setIsLoading(false);
        return;
      }
    }

    try {
      const valetService = new ValetService();
      const data = await valetService.updateStatusonly({
        id: valetId,
        status,
      });
      if (data.data?.valet) {
        setValet(data.data.valet);
        history.push("/valet");
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // get query param from url
    const urlParams = new URLSearchParams(window.location.search);
    const valetId = urlParams.get("valetId");
    if (!valetId) {
      window.history.back();
    } else {
      getValetDetails(valetId);
    }
  }, []);

  return (
    <div style={{ marginBottom: 50, width: "100%" }}>
      <div className={classes.root}>Details</div>
      <div
        onClick={() => history.push(`/valet/details/edit?valetId=${valet._id}`)}
        style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
      >
        <EditIcon style={{ marginRight: 5 }} />
        Edit
      </div>
      {/* // valet details */}
      <div>
        {/* // valet main info */}
        <div className={classes.flexRow}>
          <div className={classes.detail}>
            {" "}
            <MonetizationOnIcon style={{ marginRight: 5 }} />
            {formatPrice(valet.totalCost / 100)}
          </div>

          <div className={classes.detail}>
            {" "}
            <ConfirmationNumberIcon style={{ marginRight: 5 }} />#
            {valet.ticketNumber}
          </div>

          {values?.parkedLocation && (
            <div className={classes.detail}>
              {" "}
              <LocalParkingIcon style={{ marginRight: 5 }} />
              {values?.parkedLocation}
            </div>
          )}

          {values?.parkedLocation && (
            <div className={classes.detail}>
              {" "}
              <AssignmentIndIcon style={{ marginRight: 5 }} />
              {values?.parkedBy}
            </div>
          )}

          <div className={classes.detail}>
            {" "}
            <PhoneIphoneIcon style={{ marginRight: 5 }} />
            {formatUSPhoneNumber(valet.phoneNumber)}
          </div>
          <div
            className={classes.detail}
            style={{
              textTransform: "capitalize",
              color:
                valet.status === "completed"
                  ? "black"
                  : valet.status === "entry"
                  ? "black"
                  : "white",
              border: `1px solid ${getColorByStatus(valet.status)}`,
              backgroundColor: getColorByStatus(valet.status),
              padding: 5,
              borderRadius: 10,
            }}
          >
            {" "}
            <ScheduleIcon style={{ marginRight: 5 }} />
            {getStatus(valet.status)}
          </div>
        </div>

        {/* // parked by */}
        {/* <div style={{ display: "flex" }}>
          {values?.parkedBy && (
            <div className={classes.detail} style={{ marginRight: 20 }}>
              {" "}
              <AssignmentIndIcon style={{ marginRight: 5 }} />
              <div>{values?.parkedBy}</div>
            </div>
          )}

          {values?.parkedLocation && (
            <div className={classes.detail}>
              {" "}
              <LocalParkingIcon style={{ marginRight: 5 }} />
              <div>{values?.parkedLocation}</div>
            </div>
          )}
        </div> */}

        {/* // customer name */}
        <div>
          <div className={classes.detail}>
            <AccountCircleIcon style={{ marginRight: 5 }} />{" "}
            {valet.firstName
              ? `${valet.firstName} ${valet.lastName ? valet.lastName : ""}`
              : "Customer Name"}{" "}
          </div>
        </div>

        {/* // customer info */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
              fontWeight: 900,
              fontSize: 20,
            }}
          >
            <DirectionsCarIcon style={{ marginRight: 5 }} />
            {valet.make} {valet?.model && `,${valet.model}`}
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            border: "1px solid black",
            textAlign: "center",
            padding: 15,
            borderRadius: 10,
          }}
        >
          <div style={{ fontWeight: 900, fontSize: 20 }}>
            {valet.plateNumber} - {valet.state}
          </div>
        </div>
      </div>

      {/* // valet status actions */}
      <div className={classes.statusContainer}>
        {valetStatuses.map((stat) => {
          // recently entered car status updates
          if (stat.status === "completed" && valet.status === "entry")
            return null;
          if (stat.status === "requested" && valet.status === "entry")
            return null;
          if (stat.status === "paid" && valet.status === "entry") return null;
          if (stat.status === "paid" && valet.status === "entry") return null;
          if (stat.status === "ready" && valet.status === "entry") return null;

          // parked cars status updates
          if (stat.status === "parked" && valet.status === "parked")
            return null;
          if (stat.status === "completed" && valet.status === "parked")
            return null;
          if (stat.status === "paid" && valet.status === "parked") return null;

          // ready cars status updates
          if (stat.status === "ready" && valet.status === "ready") return null;
          if (stat.status === "parked" && valet.status === "ready") return null;

          // requested car status updates
          if (
            valet.paid === true &&
            valet.status === "requested" &&
            stat.status === "paid"
          )
            return null;
          if (stat.status === "parked" && valet.status === "requested")
            return null;
          if (stat.status === "completed" && valet.status === "requested")
            return null;
          if (stat.status === "paid" && valet.status === "requested")
            return null;

          // paid cars status updates
          if (stat.status === "ready" && valet.status === "paid") return null;
          if (stat.status === "parked" && valet.status === "paid") return null;
          if (stat.status === "paid" && valet.status === "paid") return null;

          // unpaid cars status updates
          if (
            stat.status === "completed" &&
            getStatus(valet.status) === "unpaid"
          )
            return null;

          return (
            <button
              disabled={isLoading}
              key={stat.status}
              onClick={() => updateValetStatus(valet, stat.status)}
              style={{
                marginTop: 50,
                display: "flex",
                fontWeight: 900,
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                border:
                  valet.status === "paid"
                    ? "1px solid black"
                    : valet.status === stat.status
                    ? "1px solid black"
                    : "none",
                borderRadius: 5,
                padding: 10,
                minWidth: 200,
                cursor: "pointer",
                height: 80,
                backgroundColor:
                  valet.status === "paid"
                    ? "black"
                    : valet.status === stat.status
                    ? "white"
                    : stat.color,
                color:
                  valet.status === "paid"
                    ? "white"
                    : valet.status === stat.status
                    ? "black"
                    : "white",
              }}
            >
              {isLoading
                ? "Loading..."
                : !valet.parkedBy && valet.status === "entry"
                ? "ENTER PARKING DETAILS"
                : stat.label}
            </button>
          );
        })}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          disabled={isLoading}
          onClick={() => window.history.back()}
          style={{
            marginTop: 50,
            display: "flex",
            fontWeight: 900,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid black",
            borderRadius: 5,
            padding: 10,
            marginBottom: 100,
            cursor: "pointer",
            height: 80,
            backgroundColor: "white",
            color: "black",
            width: "100%",
          }}
        >
          Back
        </button>
      </div>

      {/* // errors */}
      <div>
        {errorMessage && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default ValetDetails;
