import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { capitalize } from "../utils/FrontEndHelpers";
import HomeIcon from "@material-ui/icons/LocationOn";
import ClockIcon from "@material-ui/icons/DateRange";
import DirectionsCar from "@material-ui/icons/DirectionsCar";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    width: "280px",
    padding: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "10px",
    margin: "15px 0",
  },
  locationCost: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0",
    fontWeight: "bold",
  },
  address: {
    display: "flex",
    fontSize: "10px",
    color: "gray",
    margin: "15px 0",
  },
  timeDiv: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
  },
  timeDetails: {
    display: "flex",
    fontSize: "12px",
    justifyContent: "space-between",
  },
}));

const History = ({ booking }) => {
  const classes = useStyles();
  return (
    <div key={booking.id} className={classes.mainDiv}>
      <div className={classes.locationCost}>
        <div>{capitalize(booking.locationName)}</div>
        <div>${booking.totalCost.toFixed(2)}</div>
      </div>
      <div className={classes.address}>
        <div style={{ margin: "0 10px 0 0" }}>
          <HomeIcon fontSize="small" />
        </div>
        <div style={{ width: "180px" }}>
          <div>{booking.locationAddress}</div>
        </div>
      </div>
      <div className={classes.address}>
        <div style={{ margin: "0 10px 0 0" }}>
          <DirectionsCar fontSize="small" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <span style={{ fontWeight: 800, fontSize: 15 }}>
              {booking.plateNumber}
            </span>
          </div>
        </div>
      </div>
      <div className={classes.timeDiv}>
        <div style={{ margin: "0 10px 0 0" }}>
          <ClockIcon fontSize="small" />
        </div>
        <div className={classes.timeDetails}>
          {booking.isHourly ? (
            <>
              <div>{booking.startTime}</div>
              <div
                style={{
                  height: "40px",
                  border: "1px solid",
                  margin: "0 7px 0 2px",
                }}
              ></div>
              <div>{booking.endTime}</div>
            </>
          ) : (
            <>
              <div>1 Day</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default History;
