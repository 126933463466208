import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import TextField from "@material-ui/core/TextField";
import PlacesAutocomplete from "../components/AutoCompleteInputField";
import styled from "styled-components";
import LocationService from "../services/LocationService";
import { isAuth } from "../utils/AuthHelpers/AuthCookieHandler";

const LocationAddPage = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [isInputLoading, setInputLoading] = useState("");
  const [locationName, setLocationName] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [errorMessages, setErrorMessages] = useState("");
  const [errorLocNameMessages, setErrorLocNameMessages] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AppendAddress = (otheraddy) => {
    setAllAddress(otheraddy);
  };

  const ClearLocAdErrorMessage = () => {
    setErrorLocNameMessages("");
  };

  const setInputToLoading = (dec) => {
    setInputLoading(dec);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const LocationObj = {
      userId: isAuth()._id,
      name: allAddress.locationName,
      phoneNumber: phoneNumber,
      address: allAddress.address,
      place_id: allAddress.place_id,
      location_point: { coordinates: allAddress.coordinates },
    };

    // console.log({ LocationObj });
    // make call here
    try {
      const location = new LocationService();
      await location.create(LocationObj);
      history.push("/locations-vendor");
    } catch (err) {
      console.log(err);
      setErrorMessages(err.message);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        minHeight: "60vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ textAlign: "center" }}>Add Location</h1>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          label={"Name"}
          variant="outlined"
          type="text"
          onChange={(e) => setLocationName(e.target.value)}
          value={locationName}
          disabled={isLoading}
          required
        />{" "}
        <br />
        <PlacesAutocomplete
          locationName={locationName}
          appendAddy={AppendAddress}
          setInputToLoading={setInputToLoading}
          isInputToLoading={isInputLoading}
          clearLocAdErrorMessage={ClearLocAdErrorMessage}
          isLoading={isLoading}
        />
        <TextField
          label={"Phone Number"}
          variant="outlined"
          type="tel"
          onChange={(e) => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          disabled={isLoading}
          required
        />{" "}
        {errorLocNameMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorLocNameMessages}
          </div>
        )}
        {errorMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessages}
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SubmitLocationButton onClick={(e) => handleSubmit(e)}>
            Submit
          </SubmitLocationButton>
        </div>
      </div>
    </div>
  );
};

const SubmitLocationButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: lightcoral;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #f6b2b2;
    color: #fff;
  }
`;

export default LocationAddPage;
