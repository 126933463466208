import React from "react";
import SessionService from "../services/SessionService";
import { getCurrentTimes } from "../utils/FrontEndHelpers";
import PaypalButton from "./PaypalButton";

const CLIENT = {
  sandbox:
    "ASNGeWZXbht3LORkoJIa74XMCUbX-P-KpizixcKPWLL5299lAyrdEslU9XSB-bhgVpe8lfyaEHQXClNv",
  production:
    "AUaVx4l2tZ_eN8Yp2z1BQozFnZLzBJRDlbqeM-8Ot7F7utLTtzmBP3JUsfySxhQFS5cuoY7DLlSjNk2A",
};

const ENV = "production";

const PaypalComponent = ({
  sessionData,
  handleNext,
  setSession,
  setPaymentError,
}) => {
  const onApprove = async () => {
    const times = getCurrentTimes(sessionData);
    try {
      const session = new SessionService();
      const {
        data: { data },
      } = await session.create({
        fees: sessionData.fees,
        hours: sessionData.hours,
        startTime: times.startTime,
        endTime: times.endTime,
        zoneId: sessionData.zoneId,
        vehicleId: sessionData.vehicleId,
        rate: sessionData.rate,
        isHourly: sessionData.isHourly,
        locationId: sessionData.locationId,
        locationName: sessionData.locationName,
        locationAddress: sessionData.locationAddress,
        user: sessionData.user,
        vehiclePlate: sessionData.vehiclePlate,
        vehicleState: sessionData.vehicleState,
        vehicleMake: sessionData.vehicleMake,
        vehicleModel: sessionData.vehicleModel,
        vehicleYear: sessionData.vehicleYear,
        vehicleDescription: sessionData.vehicleDescription,
        vehicleImageUrl: sessionData.vehicleImageUrl,
      });

      setSession({
        ...sessionData,
        totalCost: data.totalCost,
        startTime: data.startTime,
        endTime: data.endTime,
      });
      setTimeout(() => {
        handleNext();
      }, 300);
    } catch (err) {
      console.error(err);
    }
  };

  const onSuccess = async (payment) => {
    const tokObj = {
      ppSuc: payment.paid,
      ppCanc: payment.cancelled,
      ppAmount: sessionData.totalCost,
    };

    if (tokObj.ppSuc) {
      await onApprove();
    }
  };

  const onError = (error) => {
    setPaymentError("Looks like something went wrong, please try again");
    console.log("Erroneous payment OR failed to load script!", error);
  };

  const onCancel = (data) => console.log("Cancelled payment!", data);

  return (
    <div>
      <PaypalButton
        client={CLIENT}
        env={ENV}
        commit={true}
        currency={"USD"}
        total={sessionData.hours * sessionData.rate + sessionData.fees}
        onSuccess={onSuccess}
        onError={onError}
        onCancel={onCancel}
      />
    </div>
  );
};

export default PaypalComponent;
