import moment from "moment";

export const isEmpty = (textString) => {
  const data = textString.toString();
  if (typeof data === "undefined") {
    return true;
  } else if (!data) {
    return true;
  } else if (data.trim() === "") {
    return true;
  } else if (/\S/.test(data) === "") {
    return true;
  } else {
    return false;
  }
};

export function formatUSPhoneNumber(phoneNumber) {
  // Check if the phone number is a string and is not null or undefined
  if (
    typeof phoneNumber !== "string" ||
    phoneNumber === null ||
    phoneNumber === undefined
  ) {
    return null;
  }

  // Remove any non-digits from the phone number
  phoneNumber = phoneNumber.replace(/\D/g, "");

  // Check if the phone number is 10 digits long
  if (phoneNumber.length !== 10) {
    return null;
  }

  // Format the phone number to (###) ###-####
  return (
    "(" +
    phoneNumber.slice(0, 3) +
    ") " +
    phoneNumber.slice(3, 6) +
    "-" +
    phoneNumber.slice(6, 10)
  );
}

export const roundUp = (n, dp) => {
  // const h = +"1".padEnd(dp + 1, "0"); // 10 or 100 or 1000 or etc
  // return Math.round(n * h) / h;

  return Number(n.toFixed(2));
};

export const formatPrice = (num) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(num);
};

// export const formatPrice = (price) => {
//   console.log(price);
//   let pr = roundUp(price, 2);
//   if (Number.isSafeInteger(price)) {
//     console.log("is safe integer");
//   }
//   return pr;
// };

export const capitalize = (str) => {
  if (str !== undefined) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
};

export const getCurrentTimes = (sessionData) => {
  let durationObjec = {};
  const startTime = moment().add(10, "minutes").format("LLLL");
  const endTime = moment()
    .add(
      sessionData.isHourly ? sessionData.hours : 1,
      sessionData.isHourly ? "hours" : "day"
    )
    .add(10, "minutes")
    .format("LLLL");
  durationObjec.startTime = startTime;
  durationObjec.endTime = endTime;
  return durationObjec;
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "Novermber",
  "December",
];
