import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import LocationService from "../services/LocationService";
import { capitalize, isEmpty } from "../utils/FrontEndHelpers";

const LocationEditPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const locationId = useLocation().state._id;

  const getLocation = async () => {
    const locationService = new LocationService();
    const { data } = await locationService.get(locationId);
    setLocationName(capitalize(data.data.location.name));
    setPhoneNumber(data.data.location.phoneNumber || "");
  };

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    getLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    // check for empty fields
    if (isEmpty(locationName) && isEmpty(phoneNumber)) {
      setErrorMessages("Please fill in all fields");
      setLoading(false);
      return;
    }

    // make call here
    try {
      const location = new LocationService();
      await location.update(locationId, { locationName, phoneNumber });
    } catch (err) {
      console.log(err);
      setErrorMessages(err.message);
    }

    setLoading(false);
  };

  return (
    <div>
      <div>
        <div
          style={{
            textAlign: "center",
            margin: "35px 0",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          Edit Location
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            label={"Name"}
            variant="outlined"
            type="text"
            onChange={(e) => setLocationName(e.target.value)}
            value={locationName}
            disabled={isLoading}
          />{" "}
          <br />
          <TextField
            label={"Phone Number"}
            variant="outlined"
            type="tel"
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            disabled={isLoading}
          />{" "}
          {errorMessages && (
            <div style={{ color: "red", textAlign: "center" }}>
              {errorMessages}
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SubmitLocationButton onClick={(e) => handleSubmit(e)}>
              Update
            </SubmitLocationButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubmitLocationButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: lightcoral;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #f6b2b2;
    color: #fff;
  }
`;

export default LocationEditPage;
