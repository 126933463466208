import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BillingService from "../../services/BillingService";
import { formatPrice } from "../../utils/FrontEndHelpers";
import RevenueTable from "./RevenueTable";
import CircularIndeterminate from "../../components/shared/CircleLoader";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  status: {
    padding: "6px",
    cursor: "pointer",
    borderRadius: "20px",
    border: "1px solid",
    margin: "0 2px",
  },
  month: {
    padding: "5px",
    cursor: "pointer",
    borderRadius: "20px",
    border: "1px solid",
    margin: "5px",
    maxWidth: "90px",
    textAlign: "center",
    "&:hover": { backgroundColor: "#f5f5f5" },
  },
  monthGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "5px",
    margin: "0 15px",
  },
}));

const LotRevenuePage = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { lotId } = useParams();
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [locationRevenue, setLocationRevenue] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [locationName, setLocationName] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [currentMonth, setCurrentMonth] = useState("");
  // const [odogwu, setOdogwu] = useState(false);

  function isString(value) {
    return typeof value === "string" || value instanceof String;
  }

  const getLotRevenue = async (argMonth, argYear) => {
    try {
      const d = new Date();
      const mnth = d.getMonth() + 1;
      const year = argYear ?? d.getFullYear();
      const stringMonth = argMonth ?? mnth;
      const tempMonth = isString(argMonth)
        ? months.findIndex((m) => m.label === argMonth) + 1
        : argMonth;
      // check if month is a string or number

      // const month = stringMonth typeof "string" ? months.findIndex((m) => m.value === stringMonth) + 1 : stringMonth;
      const month = tempMonth ?? stringMonth;
      // console.log({ stringMonth, argMonth, year, month });
      setIsLoading(true);
      setLocationAddress(state.locationAddress);
      setLocationName(state.locationName);
      const billingService = new BillingService();
      const { data } = await billingService.getLotRevenue({
        lotId,
        month: month ?? selectedMonth,
        date: new Date(),
        year: year ?? selectedYear,
      });
      setSelectedMonth(months[month - 1]?.label);
      setTotalCost(data?.totalCost);
      setLocationRevenue(data.monthSessions);
      month && setCurrentMonth(months[month - 1]?.label);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // const usr = JSON.parse(localStorage.getItem("user"));
    // setOdogwu(usr?.role === "odogwu");
    getLotRevenue();
    // const d = new Date();
    // const mnth = d.toLocaleString("default", { month: "long" });
    // setCurrentMonth(mnth);
    // setSelectedYear(d.getFullYear());
    // console.log({ first: d.getFullYear(), second: mnth });

    // eslint-disable-next-line
  }, []);

  // months object
  const months = [
    {
      label: "January",
      value: 1,
    },
    {
      label: "February",
      value: 2,
    },
    {
      label: "March",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "May",
      value: 5,
    },
    {
      label: "June",
      value: 6,
    },
    {
      label: "July",
      value: 7,
    },
    {
      label: "August",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "December",
      value: 12,
    },
  ];

  const years = ["2023", "2024", "2025", "2026", "2027"];

  const selectAYear = (year) => {
    console.log(year);
    console.log(selectedYear);
    console.log(selectedMonth);
    setSelectedYear(year);
    getLotRevenue(selectedMonth, year);
  };

  const selectAMonth = (month) => {
    console.log(month);
    console.log(selectedMonth);
    setSelectedMonth(Number(month));
    getLotRevenue(Number(month), selectedYear);
  };

  // const goToPage = () => {
  //   history.push({
  //     pathname: `/revenue/lot/${lotId}/new`,
  //   });
  // };

  // select component for months
  const MonthSelector = () => {
    return (
      <select
        value={selectedMonth}
        onChange={(e) => selectAMonth(e.target.value)}
        style={{
          width: 200,
          height: 40,
          borderRadius: 5,
          padding: 5,
          marginBottom: 30,
        }}
      >
        <option value="">{currentMonth}</option>
        {months.map((mn, idx) => {
          return (
            <option key={idx} value={mn.value}>
              {mn.label}
            </option>
          );
        })}
      </select>
    );
  };

  const YearSelector = () => {
    return (
      <select
        value={selectedYear}
        onChange={(e) => selectAYear(e.target.value)}
        style={{
          width: 200,
          height: 40,
          borderRadius: 5,
          padding: 5,
          marginBottom: 30,
        }}
      >
        <option value="">{selectedYear}</option>
        {years.map((mn, idx) => {
          return (
            <option key={idx} value={mn}>
              {mn}
            </option>
          );
        })}
      </select>
    );
  };

  if (!locationRevenue) return <div>No revenues for this location</div>;

  return (
    <div className={classes.mainDiv}>
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Location Revenues:{" "}
              {selectedMonth ? selectedMonth : "Month To Date"}
            </div>
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <div>{formatPrice(totalCost / 100)}</div>
              <div style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                {locationName}
              </div>
              <div style={{ color: "gray" }}>{locationAddress}</div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <MonthSelector />
              <YearSelector />
            </div>
            {/* //TODO: Uncomment {odogwu && (
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={goToPage}
                style={{ color: "#fff", width: "280px", marginBottom: "20px" }}
                className={classes.button}
              >
                Add Session
              </Button>
            )} */}
          </div>
          <div>
            <RevenueTable revenues={locationRevenue} />
          </div>
        </>
      )}
    </div>
  );
};

export default LotRevenuePage;
