import React from "react";
import { useHistory } from "react-router-dom";

const MemberReceiptPage = () => {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <h1>Parking Confirmed!</h1>
      <p style={{ textAlign: "center" }}>Thank you for parking with us!</p>
      <button style={{ marginBottom: 18 }} onClick={() => history.goBack()}>
        Done
      </button>
    </div>
  );
};

export default MemberReceiptPage;
