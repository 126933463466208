import APIkit from ".";

class UserService {
  getMe() {
    return APIkit.get("/api/v1/me");
  }

  invite(inviteObject) {
    return APIkit.post("/api/v1/users/enforcement/invite", inviteObject);
  }

  activate(userObject) {
    return APIkit.patch("/api/v1/users/active/me", userObject);
  }

  getEnforcement() {
    return APIkit.get("/api/v1/users/enforcement/me");
  }

  update(data) {
    return APIkit.patch("/v1/users/update", data);
  }

  delete() {
    return APIkit.delete("/api/v1/users/");
  }
}

export default UserService;
