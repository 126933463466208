import React, { useEffect, useState } from "react";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { TextField, makeStyles } from "@material-ui/core";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { bkGrdColors } from "../ValetPage";
import { formatPrice, formatUSPhoneNumber } from "../../utils/FrontEndHelpers";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ValetService from "../../services/ValetService";
import ContactService from "../../services/ContactService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridAutoFlow: "row",
    gridTemplateColumns: "1fr 1fr",
    gap: "8px",
  },
  detail: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    fontWeight: 900,
    fontSize: 20,
  },
}));

const ValetReceipt = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [valet, setValet] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");

  const getColorByStatus = (status) => {
    if (status === "ready") {
      if (valet.paid === true) {
        return bkGrdColors["paid"];
      } else {
        return bkGrdColors["unpaid"];
      }
    } else {
      return bkGrdColors[status];
    }
  };

  const getStatus = (status) => {
    if (status === "ready") {
      if (valet.paid === true) {
        return "paid";
      } else {
        return "unpaid";
      }
    } else {
      return status;
    }
  };

  const getValetDetails = async (valetId) => {
    setIsLoading(true);
    try {
      const valetService = new ValetService();
      const data = await valetService.getByPhoneNumber(valetId);
      setValet(data.data.valet);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const receiptObject = {
      valet,
    };

    try {
      const contactService = new ContactService();
      await contactService.sendReceipt(receiptObject);
    } catch (err) {
      setErrorMessage(err.message);
    }

    setIsLoading(false);
    setSent(true);
  };

  useEffect(() => {
    // get query param from url
    const urlParams = new URLSearchParams(window.location.search);
    const pn = urlParams.get("pn");
    if (!pn) {
      window.history.back();
    } else {
      setErrorMessage("");
      getValetDetails(pn);
    }
  }, []);

  return (
    <div style={{ padding: "0px 30px", width: "100%" }}>
      <h1
        style={{
          color: "#27AE60",
          textAlign: "center",
        }}
      >
        Receipt for #{valet?.ticketNumber}
      </h1>

      {/* // valet details */}
      <div>
        {/* // valet main info */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.detail} style={{ fontSize: 16 }}>
            {" "}
            <MonetizationOnIcon style={{ marginRight: 5 }} />
            {formatPrice(valet?.totalCost / 100)}
          </div>

          <div className={classes.detail} style={{ fontSize: 16 }}>
            {" "}
            <ConfirmationNumberIcon style={{ marginRight: 5 }} />#
            {valet?.ticketNumber}
          </div>

          <div className={classes.detail} style={{ fontSize: 16 }}>
            {" "}
            <PhoneIphoneIcon style={{ marginRight: 5 }} />
            {formatUSPhoneNumber(valet?.phoneNumber)}
          </div>
          <div
            className={classes.detail}
            style={{
              textTransform: "capitalize",
              color:
                valet?.status === "completed"
                  ? "black"
                  : valet.status === "entry"
                  ? "black"
                  : "white",
              border: `1px solid ${getColorByStatus(valet?.status)}`,
              backgroundColor: getColorByStatus(valet?.status),
              borderRadius: 8,
            }}
          >
            {" "}
            <ScheduleIcon style={{ marginRight: 5 }} />
            {getStatus(valet?.status)}
          </div>
        </div>

        {/* // customer name */}
        <div>
          <div
            className={classes.detail}
            style={{ fontSize: 16, textTransform: "capitalize" }}
          >
            <AccountCircleIcon style={{ marginRight: 5 }} />{" "}
            {valet.firstName
              ? `${valet?.firstName} ${valet.lastName ? valet.lastName : ""}`
              : "Customer Name"}{" "}
          </div>
        </div>

        {/* // customer info */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              fontWeight: 900,
              fontSize: 16,
            }}
          >
            <DirectionsCarIcon style={{ marginRight: 5 }} />
            {valet.make}, {valet.model}
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            border: "1px solid black",
            textAlign: "center",
            padding: 15,
            borderRadius: 2,
            marginBottom: 20,
          }}
        >
          <div style={{ fontWeight: 900, fontSize: 20 }}>
            {valet.plateNumber} - {valet.state}
          </div>
        </div>
      </div>

      {/* // request or pay */}
      {!sent &&
        (getStatus(valet?.status) === "paid" ||
          getStatus(valet?.status === "completed")) && (
          <div>
            <div
              style={{
                fontSize: 30,
                color: "#27AE60",
                textAlign: "center",
                fontWeight: 900,
                marginTop: 100,
              }}
            >
              Thank You!
            </div>
            <TextField
              type="email"
              label="Send Receipt to Your Email"
              variant="outlined"
              style={{ width: "100%", marginBottom: 10 }}
              onChange={(e) =>
                setEmail(e.target.value.replace(/[^a-zA-Z0-9@.]/g, ""))
              }
              value={email}
              disabled={isLoading}
            />{" "}
            <button
              style={{ width: "100%", marginBottom: 10 }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Send Receipt
            </button>
            <button
              style={{
                width: "100%",
                marginBottom: 10,
                backgroundColor: "#27AE60",
              }}
              onClick={() => setSent(true)}
              disabled={isLoading}
            >
              Done
            </button>
          </div>
        )}

      {getStatus(valet?.status) !== "paid" &&
        getStatus(valet?.status) !== "completed" && (
          <>
            <div
              style={{
                fontSize: 30,
                color: "red",
                textAlign: "center",
                fontWeight: 900,
                marginTop: 100,
              }}
            >
              <div style={{ fontSize: 20, color: "gray" }}>
                Please provide the cash payment to the Valet staff or simply
                press button below to pay online
              </div>
              <button
                onClick={() =>
                  (window.location.href = `/valet/pay?pn=${valet?.phoneNumber}`)
                }
                style={{ width: "100%", height: 80 }}
              >
                Pay Online
              </button>
            </div>
          </>
        )}

      {(getStatus(valet?.status) === "completed" || sent) && (
        <button
          onClick={() =>
            (window.location.href = `/valet/review?pn=${valet?.phoneNumber}`)
          }
          style={{
            width: "100%",
            color: "black",
            backgroundColor: "transparent",
            border: "1px solid black",
          }}
        >
          Back
        </button>
      )}

      {errorMessage && (
        <div style={{ color: "red", textAlign: "center", marginTop: 20 }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default ValetReceipt;
