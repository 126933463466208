import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import ZoneService from "../services/ZoneService";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 280,
  },
}));

const ZoneAddPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [lotNumber, setLotNumber] = useState("");
  const [openTime, setOpenTime] = useState("06:00");
  const [closeTime, setCloseTime] = useState("23:00");
  const [errorMessages, setErrorMessages] = useState("");
  const [price, setPrice] = useState("");
  const [choice, setChoice] = useState("daily");

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const zoneObj = {
      locationId: location.state._id,
      zoneNumber: lotNumber,
      price: Number(price),
      isHourly: choice === "hourly",
      openTime,
      closeTime,
    };

    // make call here
    try {
      const zone = new ZoneService();
      await zone.create(zoneObj);
      history.push({
        pathname: `/locations/${location.state._id}/zones`,
        state: location,
      });
    } catch (err) {
      console.log(err);
      // setErrorLocNameMessages(err);
      setErrorMessages(err.message);
    }

    setLoading(false);
  };

  const handleChange = (event) => {
    setChoice(event.target.value);
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            textTransform: "capitalize",
            fontSize: "26px",
            fontWeight: "900",
          }}
        >
          {location.state.name}
        </div>
        <span>{location.state.address}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          label={"Zone Number"}
          variant="outlined"
          type="text"
          onChange={(e) => setLotNumber(e.target.value)}
          value={lotNumber}
          disabled={isLoading}
        />{" "}
        <br />
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginRight: "30px",
            }}
          >
            <div>
              <Radio
                checked={choice === "hourly"}
                onChange={handleChange}
                value="hourly"
                label="hourly"
                name="radio-button-hourly"
                inputProps={{ "aria-label": "hourly radio" }}
              />{" "}
              <span>Hourly</span>
            </div>
            <div>
              <Radio
                checked={choice === "daily"}
                onChange={handleChange}
                value="daily"
                label="daily"
                name="radio-button-daily"
                inputProps={{ "aria-label": "daily radio" }}
              />{" "}
              <span>Daily</span>
            </div>
          </div>
          <br />
          <TextField
            label={"Price Per"}
            variant="outlined"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$ </InputAdornment>
              ),
            }}
            placeholder={`${price}/${choice}`}
            onChange={(e) => setPrice(e.target.value)}
            value={price}
            disabled={isLoading}
          />{" "}
          <div>
            <TextField
              id="time"
              label="Open Time"
              type="time"
              variant="outlined"
              defaultValue={openTime}
              onChange={(e) => setOpenTime(e.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </div>
          <div>
            <TextField
              id="time"
              label="Close Time"
              type="time"
              variant="outlined"
              defaultValue={closeTime}
              onChange={(e) => setCloseTime(e.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </div>
        </div>
        {errorMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessages}
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SubmitLocationButton onClick={(e) => handleSubmit(e)}>
            Submit
          </SubmitLocationButton>
        </div>
      </div>
    </div>
  );
};

const SubmitLocationButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: lightcoral;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #f6b2b2;
    color: #fff;
  }
`;

export default ZoneAddPage;
