import React, { useEffect, useState } from "react";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import PaymentIcon from "@material-ui/icons/Payment";
import { makeStyles } from "@material-ui/core";
import ValetService from "../services/ValetService";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { bkGrdColors } from "./ValetPage";
import { formatPrice, formatUSPhoneNumber } from "../utils/FrontEndHelpers";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useLocation } from "react-router-dom";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridAutoFlow: "row",
    gridTemplateColumns: "1fr 1fr",
    gap: "8px",
  },
  detail: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    fontWeight: 900,
    fontSize: 20,
  },
}));

const ValetPayPage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [valet, setValet] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [pn, setPn] = useState(null);
  const search = useLocation().search;
  const params = new URLSearchParams(search);

  const goToReviewPage = (valet) => {
    console.log("clicking go to pay", valet);
    if (isPaidDisabled(valet)) {
      console.log("already paid");
      setErrorMessage("Already paid");
      return;
    }
    // go to review page using windows.location
    window.location.href = `/valet/pay?pn=${valet.phoneNumber}`;
  };

  const goToReceiptPage = (valet) => {
    console.log("clicking go to receipt", valet);
    if (isPaidDisabled(valet)) {
      console.log("already paid");
      setErrorMessage("Already paid");
      return;
    }
    // go to review page using windows.location
    window.location.href = `/valet/receipt?pn=${valet.phoneNumber}`;
  };

  const isPaidDisabled = (valet) => {
    if (valet.paid === true) {
      return true;
    }

    if (valet.status === "ready") {
      if (valet.paid === true) {
        return true;
      } else {
        return false;
      }
    }

    if (valet.status === "completed") {
      return true;
    }

    if (valet.status === "paid") {
      return true;
    }

    if (valet.status === "entry") {
      return true;
    }

    return false;
  };

  const showPaymentOptions = (valet) => {
    if (valet.status === "ready") {
      return true;
    }

    if (valet.status === "requested") {
      return true;
    }

    return false;
  };

  const isRequestDisabled = (valet) => {
    if (valet.status === "ready") {
      return true;
    }

    if (valet.status === "completed") {
      return true;
    }

    if (valet.status === "Completed") {
      return true;
    }

    return false;
  };

  const getValetDetails = async (phoneNumber) => {
    setIsLoading(true);
    try {
      const valetService = new ValetService();
      const data = await valetService.getByPhoneNumber(phoneNumber);
      setValet(data.data.valet);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequest = async () => {
    setIsLoading(true);

    // if (valet.status === "requested") {
    //   setErrorMessage("We are getting your car in a moment.");
    //   setIsLoading(false);
    //   return;
    // }

    if (valet.status === "ready") {
      setErrorMessage("Your car is ready for you.");
      setIsLoading(false);
      return;
    }

    if (valet.status === "completed") {
      setErrorMessage("Your car is ready.");
      return;
    }

    if (valet.status !== "requested") {
      swal({
        title: "Are you sure?",
        text: "Once requested, you will be notified when your vehicle is ready!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancel",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Confirm",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            const valetService = new ValetService();

            if (valet) {
              const response = await valetService.updateStatusonly({
                id: valet._id,
                status:
                  valet.status === "requested" ? "unrequested" : "requested",
              });
              setValet(response.data.valet);
              window.location.reload(false);
            } else {
              // reload window
              window.location.reload(false);
            }
          } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
          } finally {
            setIsLoading(false);
          }

          swal(
            "Requested!",
            "You will be notified when your vehicle is ready!",
            "success"
          );
        }
      });
    } else {
    }
    swal({
      title: "Are you sure?",
      text: "This will instruct our team to bring your car to the front.",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Confirm",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const valetService = new ValetService();

          if (valet) {
            const response = await valetService.updateStatusonly({
              id: valet._id,
              status:
                valet.status === "requested" ? "unrequested" : "requested",
            });
            setValet(response.data.valet);
            window.location.reload(false);
          } else {
            // reload window
            window.location.reload(false);
          }
        } catch (error) {
          console.log(error);
          setErrorMessage(error.message);
        } finally {
          setIsLoading(false);
        }

        swal(
          "Unrequested!",
          "Just let us know when you're ready to pick up your vehicle!",
          "success"
        );
      }
    });
  };

  useEffect(() => {
    // get query param from url
    const pn = params.get("pn");

    if (pn) {
      setErrorMessage("");
      setPn(pn);
      getValetDetails(pn);
    } else {
      window.history.back();
    }
  }, []);

  return (
    <div style={{ padding: "0px 30px" }}>
      <h1
        style={{
          color: "#27AE60",
          textDecoration: "underline",
          textAlign: "center",
        }}
      >
        Ops Valet
      </h1>

      {/* // valet details */}
      {valet ? (
        <>
          <div>
            {/* // valet main info */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={classes.detail} style={{ fontSize: 16 }}>
                {" "}
                <MonetizationOnIcon style={{ marginRight: 5 }} />
                {formatPrice(valet?.totalCost / 100)}
              </div>

              <div className={classes.detail} style={{ fontSize: 16 }}>
                {" "}
                <ConfirmationNumberIcon style={{ marginRight: 5 }} />#
                {valet.ticketNumber}
              </div>

              <div className={classes.detail} style={{ fontSize: 16 }}>
                {" "}
                <PhoneIphoneIcon style={{ marginRight: 5 }} />
                {formatUSPhoneNumber(valet.phoneNumber)}
              </div>
              <div
                className={classes.detail}
                style={{
                  textTransform: "capitalize",
                  color:
                    valet.status === "completed"
                      ? "black"
                      : valet.status === "entry"
                      ? "black"
                      : "white",
                  border: `1px solid ${bkGrdColors[valet.status]}`,
                  backgroundColor: bkGrdColors[valet.status],
                  borderRadius: 8,
                }}
              >
                {" "}
                <ScheduleIcon style={{ marginRight: 5 }} />
                {valet.status === "ready" ? "Car Ready" : valet.status}
              </div>
            </div>

            {/* // customer name */}
            <div>
              <div
                className={classes.detail}
                style={{ fontSize: 16, textTransform: "capitalize" }}
              >
                <AccountCircleIcon style={{ marginRight: 5 }} />{" "}
                {valet.firstName
                  ? `${valet.firstName} ${valet.lastName ? valet.lastName : ""}`
                  : "Customer Name"}{" "}
              </div>
            </div>

            {/* // customer info */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 900,
                  fontSize: 16,
                }}
              >
                <DirectionsCarIcon style={{ marginRight: 5 }} />
                {valet.make}, {valet.model}
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                border: "1px solid black",
                textAlign: "center",
                padding: 15,
                borderRadius: 2,
                marginBottom: 20,
              }}
            >
              <div style={{ fontWeight: 900, fontSize: 20 }}>
                {valet.plateNumber} - {valet.state}
              </div>
            </div>
          </div>

          {/* // request or pay */}
          {!showPaymentOptions(valet) ? null : (
            <>
              <div className={classes.root}>
                <button
                  onClick={() => goToReviewPage(valet)}
                  disabled={isLoading || isPaidDisabled(valet)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: 100,
                    justifyContent: "center",
                    backgroundColor: isPaidDisabled(valet) ? "grey" : "#f39c12",
                  }}
                >
                  <PaymentIcon style={{ marginRight: 5 }} />
                  {!isPaidDisabled(valet) ? "Pay Online" : "Paid"}
                </button>

                <button
                  onClick={() => goToReceiptPage(valet)}
                  disabled={isLoading || isPaidDisabled(valet)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: 100,
                    justifyContent: "center",
                    width: "100%",
                    border: "1px solid",
                    color: "#000",
                    backgroundColor: isPaidDisabled(valet) ? "grey" : "#fff",
                  }}
                >
                  <LocalAtmIcon style={{ marginRight: 5 }} />
                  {!isPaidDisabled(valet) ? "Pay Cash" : "Paid"}
                </button>
              </div>
            </>
          )}
          <button
            onClick={() => handleRequest()}
            disabled={isLoading}
            style={{
              display: "flex",
              alignItems: "center",
              height: 100,
              width: "100%",
              justifyContent: "center",
              backgroundColor: isRequestDisabled(valet) ? "grey" : "#000",
            }}
          >
            <DirectionsCarIcon style={{ marginRight: 5 }} />
            {valet.status !== "requested"
              ? "Request Your Car"
              : "Unrequest Car"}
          </button>
          {errorMessage && (
            <div style={{ color: "red", textAlign: "center", marginTop: 20 }}>
              {errorMessage}
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            fontWeight: 900,
            fontSize: 30,
            color: "red",
            marginTop: 100,
          }}
        >
          No Tickets found for this number: {formatUSPhoneNumber(pn)}
        </div>
      )}
    </div>
  );
};

export default ValetPayPage;
