import APIkit from ".";

class ValetService {
  getAll() {
    return APIkit.get("/api/v1/valets");
  }

  me() {
    return APIkit.get("/api/v1/valets");
  }

  getValetsByValetId(valetId) {
    return APIkit.get(`/api/v1/valets/${valetId}/id`);
  }

  getValetsByLocationId(locationId) {
    return APIkit.get(`/api/v1/valets/locations/${locationId}`);
  }

  get(data) {
    return APIkit.post(`/api/v1/valets/get`, data);
  }

  getById(valetId) {
    return APIkit.get(`/api/v1/valets/${valetId}`);
  }

  getByPhoneNumber(pn) {
    return APIkit.get(`/api/v1/valets/pn/${pn}`);
  }

  create(data) {
    return APIkit.post("/api/v1/valets", data);
  }

  update(id, data) {
    return APIkit.patch(`/api/v1/valets/${id}/update`, data);
  }

  updateStatus(id, status, values) {
    return APIkit.patch(`/api/v1/valets/${id}`, { status, values });
  }

  updateStatusonly({ id, status }) {
    return APIkit.patch(`/api/v1/valets/${id}/only`, { status });
  }

  delete(id) {
    return APIkit.delete(`/api/v1/valets/${id}`);
  }

  findById(id) {
    return APIkit.get(`/api/v1/valets?id=${id}`);
  }
}

export default ValetService;
