import APIkit from ".";

class ContactService {
  sendReceipt(data) {
    return APIkit.post("/api/v1/contact/sendReceipt", data);
  }

  contactUs(data) {
    return APIkit.post("/v1/contact/contact-us", data);
  }

  welcome(data) {
    return APIkit.post("/v1/contact/welcome", data);
  }
}

export default ContactService;
