import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import {
  Button,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import ValetService from "../services/ValetService";
import InfoHeader from "../components/InfoHeader";

export const bkGrdColors = {
  paid: "#27ae60",
  unpaid: "#c0392b",
  parked: "#f39c12",
  completed: "transparent",
  requested: "blue",
  unrequested: "darkgray",
  entry: "gray",
  ready: "#800080",
};

const getColorByStatus = (valet) => {
  if (valet.status === "ready") {
    if (valet.paid === true) {
      return bkGrdColors["paid"];
    } else {
      return bkGrdColors["unpaid"];
    }
  } else {
    return bkGrdColors[valet.status];
  }
};

const getStatus = (valet) => {
  if (valet.status === "ready") {
    if (valet.paid === true) {
      return "paid";
    } else {
      return "unpaid";
    }
  } else {
    return valet.status;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontSize: 40,
    fontWeight: "bold",
    color: "#27ae60",
    justifyContent: "center",
    margin: "30px 0",
    textDecoration: "underline",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: "#27ae60",
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
    width: 280,
  },
  valetCard: {
    // on hover
    "&:hover": {
      backgroundColor: "#e74c3c",
      color: "white",
    },
  },
  valetCardContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "8px",
    marginBottom: 80,
    // breakpoint
    // [theme.breakpoints.down("md")]: {
    //   gridTemplateColumns: "1fr 1fr",
    // },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      width: "100%",
    },
  },
}));

const ValetPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [valets, setValets] = useState([]);
  const [valetData, setValetData] = useState({});

  const getTicketDetails = async () => {
    setErrorMessage("");
    setIsLoading(true);
    try {
      const valetService = new ValetService();
      const data = await valetService.get({ phoneNumber });
      if (data.data.valet === null) {
        history.push("/valet/create?phoneNumber=" + phoneNumber);
      } else {
        history.push(`/valet/create?phoneNumber=${phoneNumber}&existing=true`);
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // get my valets
    const getMyValets = async () => {
      const valetService = new ValetService();
      try {
        const data = await valetService.me();
        setValets(data.data.valets);
        setValetData(data.data);
      } catch (err) {
        console.log(err);
      }
    };

    getMyValets();
  }, []);

  return (
    <div
      style={{
        marginBottom: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className={classes.root}>Valet</div>
      <div>
        <TextField
          label="Enter Customer Phone Number..."
          type="number"
          name="phoneNumber"
          disabled={isLoading}
          value={phoneNumber}
          InputLabelProps={{
            style: { fontSize: "16px" },
          }}
          InputProps={{
            maxLength: 10,
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIphoneIcon style={{ color: "light-gray" }} />{" "}
              </InputAdornment>
            ),
          }}
          required
          variant="outlined"
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      <div className={classes.buttonDiv}>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          disableElevation
          onClick={() => getTicketDetails()}
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
      <div>
        {errorMessage && (
          <div style={{ color: "red", textAlign: "center", marginTop: 10 }}>
            {errorMessage}
          </div>
        )}
      </div>
      {valets && (
        <>
          <div style={{ marginTop: 30, width: "100%" }}>
            <InfoHeader
              isLoading={isLoading}
              currentDayStats={{
                totalCars: valetData.results,
                totalRequested: valetData.totalRequested,
                totalRevenue: valetData.totalRevenue,
                totalParked: valetData.totalParked,
              }}
            />
          </div>
          <div className={classes.valetCardContainer}>
            {valets.map((valet) => {
              return (
                <button
                  onClick={() =>
                    history.push(`/valet/details?valetId=${valet._id}`)
                  }
                  key={valet._id}
                  style={{
                    marginTop: 50,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border:
                      valet.status === "entry"
                        ? "1px solid black"
                        : valet.status === "completed"
                        ? "1px solid black"
                        : `1px solid ${getColorByStatus(valet)}`,
                    borderRadius: 5,
                    padding: 10,
                    minWidth: 200,
                    cursor: "pointer",
                    height: 100,
                    backgroundColor: getColorByStatus(valet),
                    color:
                      valet.status === "completed"
                        ? "black"
                        : valet.status === "entry"
                        ? "black"
                        : "white",
                  }}
                >
                  <span>#{valet.ticketNumber}</span>
                  {/* <span style={{ fontSize: 10 }}>{valet.make}</span> */}
                  {/* <span style={{ fontSize: 8 }}>{valet.model}</span> */}
                  <span style={{ fontSize: 12 }}>{getStatus(valet)}</span>
                </button>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ValetPage;
