import axios from "axios";

const APIkit = axios.create();

// Set JSON Web Token in Client to be included in all calls
export const setClientToken = (token) => {
  APIkit.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export default APIkit;
