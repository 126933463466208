import React, { useEffect, useState } from "react";
// import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InfoHeaderAdmin from "../../components/InfoHeaderAdmin";
import CircularIndeterminate from "../../components/shared/CircleLoader";
import LocationService from "../../services/LocationService";
import ValetService from "../../services/ValetService";
import {
  capitalize,
  formatPrice,
  formatUSPhoneNumber,
} from "../../utils/FrontEndHelpers";
import { Button } from "@material-ui/core";
import moment from "moment";

const mockValets = [
  {
    firstName: "John",
    lastName: "Doe",
    phoneNumber: "1234567890",
    status: "parked",
    rate: 100,
    color: "red",
    plateNumber: "ABC123",
    model: "Camry",
    make: "Toyota",
    parkedBy: "John Doe",
    parkedLocation: "Lot 1",
    ticketNumber: "1234567890",
    state: "CA",
  },
  {
    firstName: "Jane",
    lastName: "Doe",
    phoneNumber: "1234567890",
    status: "parked",
    rate: 100,
    color: "red",
    plateNumber: "ABC123",
    model: "Camry",
    make: "Toyota",
    parkedBy: "John Doe",
    parkedLocation: "Lot 1",
    ticketNumber: "1234567890",
    state: "CA",
  },
  {
    firstName: "Alice",
    lastName: "Smith",
    phoneNumber: "0987654321",
    status: "requested",
    rate: 120,
    color: "blue",
    plateNumber: "XYZ789",
    model: "Accord",
    make: "Honda",
    parkedBy: "Alice Smith",
    parkedLocation: "Lot 2",
    ticketNumber: "0987654321",
    state: "NY",
  },
  {
    firstName: "Bob",
    lastName: "Johnson",
    phoneNumber: "1122334455",
    status: "completed",
    rate: 150,
    color: "black",
    plateNumber: "LMN456",
    model: "Civic",
    make: "Honda",
    parkedBy: "Bob Johnson",
    parkedLocation: "Lot 3",
    ticketNumber: "1122334455",
    state: "TX",
  },
  {
    firstName: "Charlie",
    lastName: "Brown",
    phoneNumber: "2233445566",
    status: "cancelled",
    rate: 90,
    color: "white",
    plateNumber: "DEF123",
    model: "Corolla",
    make: "Toyota",
    parkedBy: "Charlie Brown",
    parkedLocation: "Lot 4",
    ticketNumber: "2233445566",
    state: "FL",
  },
  {
    firstName: "David",
    lastName: "Williams",
    phoneNumber: "3344556677",
    status: "unpaid",
    rate: 110,
    color: "green",
    plateNumber: "GHI789",
    model: "Mustang",
    make: "Ford",
    parkedBy: "David Williams",
    parkedLocation: "Lot 5",
    ticketNumber: "3344556677",
    state: "NV",
  },
  {
    firstName: "Eve",
    lastName: "Davis",
    phoneNumber: "4455667788",
    status: "entry",
    rate: 130,
    color: "yellow",
    plateNumber: "JKL012",
    model: "Model S",
    make: "Tesla",
    parkedBy: "Eve Davis",
    parkedLocation: "Lot 6",
    ticketNumber: "4455667788",
    state: "WA",
  },
];

const ManagementPage = ({ history }) => {
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [selected, setSelected] = useState(false); // boolean
  const [valets, setValets] = useState([]);
  const [valetData, setValetData] = useState({});

  useEffect(() => {
    setLoading(true);
    const locationService = new LocationService();
    const getLocation = async () => {
      const { data } = await locationService.getMyAll();
      setLocations(data.data.locations);
    };
    getLocation();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFirstSelect = async (event) => {
    setSelected(true);
    try {
      const valetService = new ValetService();
      const data = await valetService.getValetsByLocationId(
        event.target.value._id
      );
      setLocation(event.target.value.name);
      setValetData(data.data.valetData);
      setValets(data.data.valets);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
      }}
    >
      {isLoading ? (
        <CircularIndeterminate />
      ) : locations.length > 0 ? (
        <>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Select Valet Location
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={location}
              onChange={handleFirstSelect}
              label="Select Valet Location"
            >
              {locations.map((location, idx) => {
                return (
                  <MenuItem value={location} key={idx}>
                    {capitalize(location.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div style={{ width: "100%" }}>
            {selected && (
              <InfoHeaderAdmin
                isLoading={isLoading}
                currentDayStats={{
                  totalCars: valetData.results,
                  totalRequested: valetData.totalRequested,
                  totalRevenue: valetData.totalRevenue,
                  totalParked: valetData.totalParked,
                }}
              />
            )}
            <div>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Ticket Number</th>
                    <th>Phone Number</th>
                    <th>Status</th>
                    <th>Rate</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Plate Number</th>
                    <th>State</th>
                    <th>Color</th>
                    <th>Parked By</th>
                    <th>Parked Location</th>
                  </tr>
                </thead>
                <tbody>
                  {valets?.length
                    ? valets?.map((valet, idx) => (
                        <tr
                          key={idx}
                          style={{
                            borderBottom: 1,
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        >
                          <td
                            style={{
                              border: 1,
                              paddingLeft: 20,
                            }}
                          >
                            {`${valet.firstName} ${valet.lastName}`}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {moment(valet.createdAt).format("MM/DD/YYYY")}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            #{valet.ticketNumber}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {formatUSPhoneNumber(valet.phoneNumber)}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {valet.status}
                          </td>
                          <td
                            style={{
                              border: 1,
                              paddingLeft: 20,
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {formatPrice(Number(valet?.totalCost / 100))}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {valet.make}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {valet.model}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {valet.plateNumber}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {valet.state}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {valet.color}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {valet.parkedBy}
                          </td>
                          <td style={{ border: 1, paddingLeft: 20 }}>
                            {valet.parkedLocation}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div>
          <p style={{ textAlign: "center", fontSize: 20 }}>
            No locations found
          </p>

          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => history.push("/locations-vendor")}
            disabled={isLoading}
            style={{
              backgroundColor: "#27AE60",
              color: "white",
              textTransform: "capitalize",
              width: "100%",
            }}
          >
            Go To Valet Locations
          </Button>
        </div>
      )}
    </div>
  );
};

export default ManagementPage;
