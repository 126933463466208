import APIkit from ".";

class BillingService {
  createStripeIntent(data) {
    return APIkit.post("/api/v1/billing/stripe-intent", data);
  }

  getRevenue() {
    return APIkit.post("/api/v1/billing/revenue");
  }

  getLotRevenue({ lotId, month, date, year }) {
    return APIkit.post("/api/v1/billing/lot/revenue", {
      lotId,
      month,
      date,
      year,
    });
  }
}

export default BillingService;
