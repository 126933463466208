import React from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { Provider, createStoreHook } from "react-redux";
// import { createStore, applyMiddleware, compose } from "redux";
// import thunk from "redux-thunk";
import "./index.css";
// import reducers from "./reducers";
import App from "./App";

// const store = createStore(reducers, compose(applyMiddleware(thunk)));

const stripePromise = loadStripe(
  "pk_live_51Jm8TIL6ikT6q4pCa73VON53SDVq1BUBI0rxy7qKO7EoZMCJv5WbtJDwT8LT6VWc66GM0sGUIw6mLKGiZ0WvJ07l000xwEcqt9"
);

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <App />
  </Elements>,
  document.getElementById("root")
);
