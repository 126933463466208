import React, { useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import LinearProgress from "@material-ui/core/LinearProgress";

const AutoCompleteInputField = ({
  locationName,
  appendAddy,
  setInputToLoading,
  isInputToLoading,
  clearLocAdErrorMessage,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const [hasSelected, setHasSelected] = useState(false);

  const ref = useRef();
  useOnclickOutside(ref, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    if (hasSelected) {
      setHasSelected(false);
    }
    clearLocAdErrorMessage();
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description, place_id }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter as "false"

      setInputToLoading(true);
      setValue(description, true);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          appendAddy({
            place_id: place_id,
            locationName: locationName,
            address: description,
            coordinates: [lng, lat],
          });
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });

      setHasSelected(true);
      setInputToLoading(false);
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id = Math.random(),
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li id="suggest" key={id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <TextField
        value={value}
        onChange={handleInput}
        disabled={!ready}
        variant="outlined"
        label={"Address"}
        required
      />
      {isInputToLoading && <LinearProgress />}
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && !hasSelected ? (
        <ul id="suggest-list">{renderSuggestions()}</ul>
      ) : null}
    </div>
  );
};

export default AutoCompleteInputField;
