export const cl = {
  primary: "#27ae60",
  secondary: "#2ecc71",
  neutral: "#04a599",
  tertiary: "#3498db",
  quaternary: "#9b59b6",
  quinary: "#f1c40f",
  senary: "#e67e22",
  brown: "#d1804c",
  action: "#ff5a5e",
  action_hover: "#ff8b8e",
  septenary: "#e74c3c",
  octonary: "#ecf0f1",
  nonary: "#95a5a6",
  denary: "#34495e",
  black: "#000000",
  white: "#ffffff",
  gray: "#808080",
  lightgray: "#d3d3d3",
  darkgray: "#a9a9a9",
  lightblue: "#add8e6",
  darkblue: "#00008b",
  blue1: "#0d47f4",
  lightcyan: "#e0ffff",
};
