import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import HomeIcon from "@material-ui/icons/LocationOn";
import ClockIcon from "@material-ui/icons/DateRange";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import { capitalize, isEmpty } from "../../utils/FrontEndHelpers";
import SessionService from "../../services/SessionService";
import LocationService from "../../services/LocationService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  vehicleDiv: {
    width: "280px",
    padding: 20,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "10px",
    marginTop: "30px",
  },
  pageDiv: {
    display: "flex",
    height: "120px",
    width: "120px",
    margin: "25px 0",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "15px",
  },
  rootPageDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainDiv: {
    width: "280px",
    padding: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "10px",
    margin: "15px 0",
  },
  locationCost: {
    display: "flex",
    justifyContent: "space-between",
    margin: "25px 0",
    padding: "0 10px",
    fontWeight: "bold",
    alignItems: "center",
  },
  address: {
    display: "flex",
    fontSize: "10px",
    color: "gray",
    margin: "15px 0",
    alignItems: "center",
  },
  timeDiv: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
  },
  timeDetails: {
    display: "flex",
    fontSize: "12px",
    justifyContent: "space-between",
  },
}));

const SearchVehiclePage = ({ match }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [vehicle, setVehicle] = useState({});
  const [status, setStatus] = useState(false);
  const [zone, setZone] = useState({});
  const [location, setLocation] = useState({});
  const [sessions, setSessions] = useState([]);
  const [values, setValues] = useState({
    license: "",
    error: "",
    isLoading: false,
  });

  const getLocation = async () => {
    const locationService = new LocationService();
    const { data } = await locationService.get(match.params.locationId);
    setLocation(data.data.location);
    setSessions(data.data.sessions);
  };

  useEffect(() => {
    getLocation();

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { license, isLoading } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const searchVehicle = async () => {
    setVehicle({});

    if (isEmpty(license)) {
      setError("Please enter a license plate number");
      return;
    }

    setValues({ ...values, isLoading: true });
    try {
      const sessionService = new SessionService();
      const statusObject = {
        license: license,
        currentTime: Date.now(),
        locationId: match.params.locationId,
      };
      const { data } = await sessionService.getVehicleStatus(statusObject);

      if (!data.success) {
        setError(data.message);
        setValues({ ...values, isLoading: false });
        return;
      }

      setVehicle(data.session);
      setStatus(data.status);
      setZone(data.zone);
      setValues({ ...values, license: "", isLoading: false });
    } catch (err) {
      setValues({ ...values, isLoading: false });
      setError(err?.response?.data?.message);
      console.log(err);
    }
  };

  return (
    <div className={classes.rootPageDiv}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h3 style={{ textAlign: "center" }}>
          Search Cars in: {capitalize(location.name)}
        </h3>
        <div style={{ textAlign: "center", fontSize: 12 }}>
          {location.address}
        </div>
        <div>
          <TextField
            label="License Plate"
            disabled={isLoading}
            value={license}
            variant="outlined"
            onChange={handleChange("license")}
            InputLabelProps={{
              style: { fontSize: "14px" },
            }}
            inputProps={{
              maxLength: 8,
              style: { textTransform: "uppercase" },
            }}
          />
        </div>
        <div>
          <SearchButton
            className={classes.button}
            disabled={isLoading}
            onClick={searchVehicle}
          >
            Search
          </SearchButton>
          {error && (
            <div
              style={{
                color: "red",
                textAlign: "center",
                width: 280,
                fontSize: 12,
                marginTop: 10,
              }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
      <div>
        {vehicle._id ? (
          <div className={classes.vehicleDiv}>
            <div className={classes.locationCost}>
              <div>{capitalize(vehicle.locationName)}</div>
              <div style={{ fontSize: 20, fontWeight: 800 }}>
                ${vehicle.totalCost.toFixed(2)}
              </div>
            </div>
            <div className={classes.address}>
              <div style={{ margin: "0 10px 0 0" }}>
                <LocalParkingIcon fontSize="small" />
              </div>
              <div style={{ width: "180px" }}>
                <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 4 }}>
                  #{zone.zoneNumber}
                </div>
              </div>
            </div>
            <div className={classes.address}>
              <div style={{ margin: "0 10px 0 0" }}>
                <HomeIcon fontSize="small" />
              </div>
              <div style={{ width: "180px" }}>
                <div>{vehicle.locationAddress}</div>
              </div>
            </div>
            <div className={classes.address}>
              <div style={{ margin: "0 10px 0 0" }}>
                <DirectionsCar fontSize="small" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <span style={{ fontWeight: 800, fontSize: 15 }}>
                    {vehicle.plateNumber}
                  </span>
                  {/* - {vehicle.year} {capitalize(vehicle.make)}{" "}
                  {capitalize(vehicle.model)} */}
                </div>
              </div>
            </div>
            <div className={classes.timeDiv}>
              {vehicle.isHourly ? (
                <>
                  <div style={{ margin: "0 10px 0 0" }}>
                    <ClockIcon fontSize="small" />
                  </div>
                  <div className={classes.timeDetails}>
                    <div>{vehicle.startTime}</div>
                    <div
                      style={{
                        height: "40px",
                        border: "1px solid",
                        margin: "0 7px 0 2px",
                      }}
                    ></div>
                    <div>{vehicle.endTime}</div>
                  </div>
                </>
              ) : (
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontWeight: "bold", marginRight: 10 }}>
                      Started:
                    </div>
                    <div style={{ fontSize: 11 }}>
                      {moment(vehicle.createdAt).format("LLL")}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      textAlign: "center",
                      height: 100,
                      border: "1px solid",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: status ? "green" : "red",
                      fontWeight: "bold",
                      width: "100%",
                      borderRadius: 8,
                    }}
                  >
                    {status ? "Valid" : "Expired"}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div>
        <Table>
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell>
                  <u>Paid License Plates</u>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.length > 0
                ? sessions.map((session) => (
                    <TableRow
                      key={session._id}
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          textTransform: "capitalize",
                          textAlign: "center",
                        }}
                      >
                        {session.plateNumber ?? "N/A"}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </TableContainer>
        </Table>
      </div>
    </div>
  );
};

const SearchButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default withRouter(SearchVehiclePage);
