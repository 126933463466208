import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const ErrorPage = ({ history }) => {
  const backPage = () => {
    history.push("/dashboard");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: 30,
          fontWeight: "bold",
          color: "#27ae60",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        Valet Pro
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <BackButton onClick={backPage}>Back Home</BackButton>
      </div>
    </div>
  );
};

const BackButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default withRouter(ErrorPage);
