import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LocationService from "../../services/LocationService";
import CircularIndeterminate from "../../components/shared/CircleLoader";
import { capitalize } from "../../utils/FrontEndHelpers";

const UpdateRevenuePage = () => {
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({});
  const [values, setValues] = useState({
    lotName: "",
    lotAddress: "",
    lotCity: "",
    lotState: "",
    lotRevenue: "",
    lotCapacity: "",
    lotOccupancy: "",
    errorMessage: "",
  });

  useEffect(() => {
    setLoading(true);
    const locationService = new LocationService();
    const getLocation = async () => {
      const { data } = await locationService.getAll();
      console.log(data.data.locations);
      setLocations(data.data.locations);
    };
    getLocation();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("Submitted");
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSelect = (event) => {
    setLocation(event.target.value);
  };

  const updateRevenue = async () => {
    const locationService = new LocationService();
    const { data } = await locationService.updateRevenue({
      locationId: location,
      lotRevenue: values.lotRevenue,
    });
    console.log(data);
  };

  return (
    <div>
      {isLoading ? (
        <CircularIndeterminate />
      ) : locations.length > 0 ? (
        <>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Location
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={location}
              onChange={handleSelect}
              label="Location"
            >
              {locations.map((location, idx) => {
                return (
                  <MenuItem value={location.id} key={idx}>
                    {capitalize(location.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextField
              label="Lot Name"
              type="text"
              name="Lot Name"
              disabled={isLoading}
              value={values.lotName}
              required
              onChange={(e) => handleChange(e.target.value)}
            />
            <br />
            <br />
            <TextField
              label="Lot Revenue"
              type="number"
              name="lotRevenue"
              disabled={isLoading}
              value={values.lotRevenue}
              required
              onChange={(e) => handleChange(e.target.value)}
            />
            <br />
            <br />
            <div
              style={{
                color: "#27ae60",
                width: "100%",
                overflowWrap: "break-word",
                fontSize: "10px",
              }}
            >
              {values.errorMessage}
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="submit-btn"
                disabled={isLoading}
                onClick={updateRevenue}
              >
                Update Revenue
              </button>
            </div>
          </form>
        </>
      ) : (
        <div>No locations found</div>
      )}
    </div>
  );
};

export default UpdateRevenuePage;
